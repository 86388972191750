import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { FORM_FIELDS_NAME } from './constant'
import { Button } from 'primereact/button'
import { BreadCrumb } from 'primereact/breadcrumb'
import { ROUTE_PATH } from '../../constant/urlConstant'
import { useNavigate, useParams } from 'react-router-dom'
import { Fieldset } from 'primereact/fieldset'
import MzAutoComplete from '../../common/MzForm/MzAutoComplete/index'
import MzInput from '../../common/MzForm/MzInput'
import { useState } from 'react'
const SubDistrictComponent = (props) => {
  const {
    formFieldValueMap,
    isLoading,
    isEditSubDistrictSuccess,
    isPageLevelError,
    isEdit,
    updateSubDistrictRecord,
    fetchDistrictList,
    fetchSubDistrictList,
    provincesList,
    districtsList,
    subDistrictsList
  } = props.addSubDistrictProps
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue
  } = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap
    }, [formFieldValueMap]),
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const history = useNavigate()
  const { id } = useParams()
  const [key, setKey] = useState(0)
  useEffect(() => {
    if (isEditSubDistrictSuccess) {
      setTimeout(() => {
        setKey((prevKey) => prevKey + 1)
        reset({
          [FORM_FIELDS_NAME.PROVINCE.name]: '',
          [FORM_FIELDS_NAME.DISTRICT.name]: '',
          [FORM_FIELDS_NAME.SUB_DISTRICT.name]: '',
          [FORM_FIELDS_NAME.NEW_NAME.name]: ''
        })
      }, 1000)
    }
    // eslint-disable-next-line
  }, [isEditSubDistrictSuccess])

  useEffect(() => {
    if (watch(FORM_FIELDS_NAME.PROVINCE.name)) {
      fetchDistrictList(watch(FORM_FIELDS_NAME.PROVINCE.name))
      setValue(FORM_FIELDS_NAME.DISTRICT.name, '')
      setValue(FORM_FIELDS_NAME.SUB_DISTRICT.name, '')
    }
    // eslint-disable-next-line
  }, [watch(FORM_FIELDS_NAME.PROVINCE.name), fetchDistrictList, setValue])

  useEffect(() => {
    if (watch(FORM_FIELDS_NAME.DISTRICT.name)) {
      fetchSubDistrictList(watch(FORM_FIELDS_NAME.DISTRICT.name))
      setValue(FORM_FIELDS_NAME.SUB_DISTRICT.name, '')
      fetchSubDistrictList(watch(FORM_FIELDS_NAME.DISTRICT.name))
    }
    // eslint-disable-next-line
  }, [watch(FORM_FIELDS_NAME.DISTRICT.name), fetchSubDistrictList, setValue])

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    )
  }

  const onSubmit = (data) => {
    if (isEdit) {
      const updatePayload = {
        id: data.taluka,
        name: data.name,
        district: {
          id: data.district
        },
        active: true
      }
      updateSubDistrictRecord(updatePayload, data?.taluka)
    }
  }

  const renderPageHeaderSection = () => {
    const breadCrumbItems = [
      {
        label: isEdit ? 'Update Taluka' : 'Add Taluka',
        command: () =>
          history(
            isEdit
              ? `${ROUTE_PATH.LINK.EDIT.replace(':id', id)}`
              : ROUTE_PATH.LINK.ADD
          )
      }
    ]
    const home = {
      icon: 'pi pi-home',
      command: () => history(ROUTE_PATH.DASHBOARD.HOME)
    }

    return (
      <div className="flex justify-content-between align-items-center  border-1 border-200  border-round  bg-white ">
        <BreadCrumb model={breadCrumbItems} home={home} className="border-0" />
      </div>
    )
  }

  const handleCancel = (e) => {
    e.preventDefault()
    setKey((prevKey) => prevKey + 1)
    reset({
      [FORM_FIELDS_NAME.PROVINCE.name]: '',
      [FORM_FIELDS_NAME.DISTRICT.name]: '',
      [FORM_FIELDS_NAME.SUB_DISTRICT.name]: '',
      [FORM_FIELDS_NAME.NEW_NAME.name]: ''
    })
  }

  return (
    <div>
      {renderPageHeaderSection()}

      <div className="flex bg-white flex-column align-items-center justify-content-center border-1 border-200 border-round mt-3 p-2 md:p-0">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-2 w-full px-4 md:mt-5"
        >
          <div className="card ">
            <Fieldset legend="Location Details">
              <div className="grid">
                <div className=" col-12 md:col-6">
                  <MzAutoComplete
                    key={key}
                    control={control}
                    name={FORM_FIELDS_NAME.PROVINCE.name}
                    label={FORM_FIELDS_NAME.PROVINCE.label}
                    optionLabel={FORM_FIELDS_NAME.PROVINCE.optionLabel}
                    optionValue={FORM_FIELDS_NAME.PROVINCE.optionValue}
                    placeholder={FORM_FIELDS_NAME.PROVINCE.placeholder}
                    isError={!!errors[FORM_FIELDS_NAME.PROVINCE.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.PROVINCE.name
                    )}
                    rules={FORM_FIELDS_NAME.PROVINCE.rules}
                    suggestions={provincesList ?? []}
                    dropdown
                    value={formFieldValueMap?.state ?? ''}
                  />
                </div>
                <div className=" col-12 md:col-6">
                  <MzAutoComplete
                    key={key}
                    control={control}
                    name={FORM_FIELDS_NAME.DISTRICT.name}
                    label={FORM_FIELDS_NAME.DISTRICT.label}
                    optionLabel={FORM_FIELDS_NAME.DISTRICT.optionLabel}
                    optionValue={FORM_FIELDS_NAME.DISTRICT.optionValue}
                    placeholder={FORM_FIELDS_NAME.DISTRICT.placeholder}
                    isError={!!errors[FORM_FIELDS_NAME.DISTRICT.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.DISTRICT.name
                    )}
                    rules={FORM_FIELDS_NAME.DISTRICT.rules}
                    suggestions={districtsList ?? []}
                    dropdown
                    value={formFieldValueMap?.district ?? ''}
                    disabled={formFieldValueMap?.state === ''}
                  />
                </div>

                <div className=" col-12 md:col-6">
                  <MzAutoComplete
                    key={key}
                    control={control}
                    name={FORM_FIELDS_NAME.SUB_DISTRICT.name}
                    label={FORM_FIELDS_NAME.SUB_DISTRICT.label}
                    optionLabel={FORM_FIELDS_NAME.SUB_DISTRICT.optionLabel}
                    optionValue={FORM_FIELDS_NAME.SUB_DISTRICT.optionValue}
                    placeholder={FORM_FIELDS_NAME.SUB_DISTRICT.placeholder}
                    isError={!!errors[FORM_FIELDS_NAME.SUB_DISTRICT.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.SUB_DISTRICT.name
                    )}
                    rules={FORM_FIELDS_NAME.SUB_DISTRICT.rules}
                    suggestions={subDistrictsList ?? []}
                    dropdown
                    value={formFieldValueMap?.taluka ?? ''}
                    disabled={formFieldValueMap?.district === 's'}
                  />
                </div>
                <div className=" col-12 md:col-6">
                  <MzInput
                    control={control}
                    name={FORM_FIELDS_NAME.NEW_NAME.name}
                    label={FORM_FIELDS_NAME.NEW_NAME.label}
                    placeholder={FORM_FIELDS_NAME.NEW_NAME.placeholder}
                    isError={!!errors[FORM_FIELDS_NAME.NEW_NAME.name]}
                    errorMsg={getFormErrorMessage(
                      FORM_FIELDS_NAME.NEW_NAME.name
                    )}
                    rules={FORM_FIELDS_NAME.NEW_NAME.rules}
                    value={formFieldValueMap?.name ?? ''}
                  />
                </div>
              </div>
            </Fieldset>
          </div>

          <div className="grid justify-content-center mt-3 mb-5">
            <div className="col-12 md:col-4">
              <Button
                label="Cancel"
                className="w-full"
                severity="secondary"
                onClick={handleCancel}
                disabled={isLoading || isPageLevelError}
              />
            </div>
            <div className="col-12 md:col-4">
              <Button
                type="submit"
                label={isEdit ? 'Update' : 'Submit'}
                className="w-full"
                disabled={isLoading || isPageLevelError}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
export default SubDistrictComponent

import { useRef } from 'react'
import { Link, useNavigate, useLocation, Outlet } from 'react-router-dom'
import { Button } from 'primereact/button'
import { ROUTE_PATH } from '../../constant/urlConstant'
import { LOGO } from '../../assets/images'
import { Menu } from 'primereact/menu'

import { useDispatch, useSelector } from 'react-redux'
import { USER_ROLE } from '../../constant/actionTypes/role'
import { logout } from '../../redux/action/auth/login'

const LayoutScreen = () => {
  const dispatch = useDispatch()
  const userMenu = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()
  const userRole = useSelector((state) => state.loginReducer?.userRole)
  const { firstName = '', lastName = '' } = useSelector(
    (state) => state.userProfileReducer?.user ?? null
  )

  const itemRenderer = (item) => {
    const isActive =
      item.commandPath === '/'
        ? location.pathname === item.commandPath
        : location.pathname.startsWith(item.commandPath)
    return (
      <div
        className={`p-menuitem-content mb-3 hover:bg-primary ${
          isActive ? 'bg-primary' : ''
        }`}
        onClick={() => item.command && item.command()}
      >
        <Link
          className={`flex align-items-center p-menuitem-link ${
            isActive ? 'text-white' : 'hover:text-white'
          }`}
        >
          <span className={item.icon} />
          <span className="mx-2 hidden md:block">{item.label}</span>
        </Link>
      </div>
    )
  }
  const handleLogout = () => {
    dispatch(logout())
  }
  const userChangePasswordItems = [
    {
      label: 'Change Password',
      // icon: 'pi pi-key',
      command: () => navigate(ROUTE_PATH.BASE.CHANGE_PASSWORD),
      commandPath: ROUTE_PATH.BASE.CHANGE_PASSWORD,
      roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.USER]
    }
  ]
  const allMenuItems = [
    {
      label: 'Home',
      icon: 'pi pi-microsoft',
      command: () => navigate(ROUTE_PATH.BASE.HOME),
      commandPath: ROUTE_PATH.BASE.HOME,
      roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.USER]
    },
    {
      label: 'Users',
      icon: 'pi pi-user',
      command: () => navigate(ROUTE_PATH.USER.HOME),
      commandPath: ROUTE_PATH.USER.HOME,
      roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN]
    },
    {
      label: 'Access',
      icon: 'pi  pi-shield',
      command: () => navigate(ROUTE_PATH.ACCESS.HOME),
      commandPath: ROUTE_PATH.ACCESS.HOME,
      roles: [USER_ROLE.SUPER_ADMIN]
    },
    {
      label: 'Links',
      icon: 'pi pi-link',
      command: () => navigate(ROUTE_PATH.LINK.HOME),
      commandPath: ROUTE_PATH.LINK.HOME,
      roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.USER]
    },
    {
      label: '7/12 & Index2',
      icon: 'pi pi-map',
      command: () => navigate(ROUTE_PATH.SEVENENQUIRY.FetchSevenEnquiry),
      commandPath: ROUTE_PATH.BASE.FetchSevenEnquiry,
      roles: [USER_ROLE.SUPER_ADMIN]
    },
    {
      label: 'Enquiry',
      icon: 'pi pi-address-book',
      command: () => navigate(ROUTE_PATH.ENQUIRY.HOME),
      commandPath: ROUTE_PATH.ENQUIRY.HOME,
      roles: [USER_ROLE.SUPER_ADMIN]
    },
    {
      label: 'Location',
      icon: 'pi pi-map-marker',
      command: () => navigate(ROUTE_PATH.LOCATION.HOME),
      commandPath: ROUTE_PATH.LOCATION.HOME,
      roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.USER]
    },

    {
      label: 'Taluka',
      icon: 'pi pi-compass',
      command: () => navigate(ROUTE_PATH.SUB_DISTRICT.HOME),
      commandPath: ROUTE_PATH.SUB_DISTRICT.HOME,
      roles: [USER_ROLE.SUPER_ADMIN]
    },
    {
      label: 'Village',
      icon: 'pi pi-compass',
      command: () => navigate(ROUTE_PATH.VILLAGE.HOME),
      commandPath: ROUTE_PATH.VILLAGE.HOME,
      roles: [USER_ROLE.SUPER_ADMIN]
    },
    {
      label: 'Logout',
      icon: 'pi pi-power-off',
      command: () => handleLogout(),
      commandPath: ROUTE_PATH.LOGOUT,
      roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.USER, '']
    }
  ]

  const getFilteredItems = () => {
    const filteredItems = allMenuItems.filter((item) =>
      item.roles.includes(userRole)
    )

    const itemsWithTemplate = filteredItems.map((item) => ({
      ...item,
      template: itemRenderer,
      items: item.items
        ? item.items.map((subItem) => ({ ...subItem, template: itemRenderer }))
        : undefined
    }))

    return [
      {
        template: () => (
          <span className="inline-flex w-full text-center align-items-center  gap-1 md:px-2">
            <div className="flex align-items-center ">
              <img src={LOGO} alt="ked" className="h-3rem" />
            </div>
            <div className="main-logo-heading font-semibold hidden md:block">
              Bhoomi 22
            </div>
          </span>
        )
      },
      { separator: true },
      ...itemsWithTemplate
    ]
  }

  const items = getFilteredItems()

  return (
    <>
      <div style={{ width: 'calc(100vw - 0px)', height: 'calc(100vh - 20px)' }}>
        <div className="flex justify-content-between w-full p-1 h-full">
          <Menu
            model={userChangePasswordItems}
            popup
            ref={userMenu}
            className="w-12rem sm:w-12rem md:w-14rem p-2 m-auto"
          />
          <div>
            <Menu
              model={items}
              className="md:w-14rem w-full h-full overflow-auto"
            />
          </div>

          <div className="flex flex-column gap-2 w-full h-full surface-border px-1">
            <div className="flex w-full z-5 py-2 border-round-sm surface-border border-1 align-items-center justify-content-end gap-2 sticky top-0 bg-white">
              <div className="flex w-full align-items-center justify-content-end gap-2 mr-2">
                <div>
                  <span className="text-500"></span>
                  {firstName} {lastName}
                </div>
                <Button
                  icon="pi pi-user"
                  rounded
                  text
                  raised
                  aria-label="User"
                  onClick={(event) => userMenu.current.toggle(event)}
                />
              </div>
            </div>
            <div className="flex-grow overflow-scroll aside-width">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LayoutScreen

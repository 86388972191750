export const INIT_SEVENENQUIRY = 'INIT_SEVENENQUIRY'

export const FETCH_SEVENENQUIRY_LIST = {
  START: 'FETCH_SEVENENQUIRY_LIST_START',
  SUCCESS: 'FETCH_SEVENENQUIRY_LIST_SUCCESS',
  ERROR: 'FETCH_SEVENENQUIRY_LIST_ERROR'
}

export const FETCH_SEVENENQUIRY_RECORD = {
  START: 'FETCH_SEVENENQUIRY_RECORD_START',
  SUCCESS: 'FETCH_SEVENENQUIRY_RECORD_SUCCESS',
  ERROR: 'FETCH_SEVENENQUIRY_RECORD_ERROR'
}

export const FETCH_ALL_SEVENENQUIRY_USER = {
  START: 'FETCH_ALL_SEVENENQUIRY_USER_START',
  SUCCESS: 'FETCH_ALL_SEVENENQUIRY_USER_SUCCESS',
  ERROR: 'FETCH_ALL_SEVENENQUIRY_USER_ERROR'
}
export const CREATE_SEVENENQUIRY_RECORD = {
  START: 'CREATE_SEVENENQUIRY_RECORD_START',
  SUCCESS: 'CREATE_SEVENENQUIRY_RECORD_SUCCESS',
  ERROR: 'CREATE_SEVENENQUIRY_RECORD_ERROR'
}

export const UPDATE_SEVENENQUIRY_RECORD = {
  START: 'UPDATE_SEVENENQUIRY_RECORD_START',
  SUCCESS: 'UPDATE_SEVENENQUIRY_RECORD_SUCCESS',
  ERROR: 'UPDATE_SEVENENQUIRY_RECORD_ERROR'
}

export const DELETE_SEVENENQUIRY = {
  START: 'DELETE_SEVENENQUIRY_START',
  SUCCESS: 'DELETE_SEVENENQUIRY_SUCCESS',
  ERROR: 'DELETE_SEVENENQUIRY_ERROR'
}

export const GET_SEVENENQUIRY_COUNT = {
  START: 'GET_SEVENENQUIRY_COUNT_START',
  SUCCESS: 'GET_SEVENENQUIRY_COUNT_SUCCESS',
  ERROR: 'GET_SEVENENQUIRY_COUNT_ERROR'
}

import {
  INIT_SEVENENQUIRY,
  FETCH_SEVENENQUIRY_LIST,
  CREATE_SEVENENQUIRY_RECORD
} from '../../../constant/actionTypes/sevenEnquiry'

// import { sevenEnquiryService } from '../../../services'
import { sevenEnquiryService } from '../../../services/'

export const initSevenEnquiry = () => ({
  type: INIT_SEVENENQUIRY
})

export const fetchSevenEnquiryStart = () => ({
  type: FETCH_SEVENENQUIRY_LIST.START
})

export const fetchSevenEnquirySuccess = (sevenEnquiry) => ({
  type: FETCH_SEVENENQUIRY_LIST.SUCCESS,
  payload: sevenEnquiry
})

export const fetchSevenEnquiryError = (error) => ({
  type: FETCH_SEVENENQUIRY_LIST.ERROR,
  payload: { error }
})

export const fetchSevenEnquiryList = (payload) => {
  return (dispatch) => {
    dispatch(fetchSevenEnquiryStart(payload))
    sevenEnquiryService
      .fetchSevenEnquiryList(payload)
      .then((sevenEnquiryData) => {
        if (!sevenEnquiryData.isError) {
          dispatch(fetchSevenEnquirySuccess(sevenEnquiryData))
        } else {
          dispatch(fetchSevenEnquiryError(sevenEnquiryData))
        }
      })
  }
}

export const createSevenEnquiryRecordStart = () => ({
  type: CREATE_SEVENENQUIRY_RECORD.START
})

export const createSevenEnquiryRecordSuccess = (newsevenEnquiry) => ({
  type: CREATE_SEVENENQUIRY_RECORD.SUCCESS,
  payload: newsevenEnquiry
})

export const createSevenEnquiryRecordError = (error) => ({
  type: CREATE_SEVENENQUIRY_RECORD.ERROR,
  payload: { error }
})

export const createSevenEnquiryRecord = (payload) => {
  return (dispatch) => {
    dispatch(createSevenEnquiryRecordStart(payload))
    sevenEnquiryService
      .createSevenEnquiryRecord(payload)
      .then((sevenEnquiryData) => {
        if (!sevenEnquiryData.isError) {
          dispatch(createSevenEnquiryRecordSuccess(sevenEnquiryData))
        } else {
          dispatch(createSevenEnquiryRecordError(sevenEnquiryData))
        }
      })
  }
}

import { Button } from 'primereact/button'
import './index.css'
import React, { useEffect, useMemo, useState } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { FORM_FIELDS_NAME } from './constant'
import { Menubar } from 'primereact/menubar'
import AddEditSevenEnquiryScreen from '../../containers/sevenEnquiryScreen/addEditSevenEnquiryScreen/index'
import { useForm } from 'react-hook-form'
import { Dialog } from 'primereact/dialog'

import LoginScreen from '../../containers/loginScreen'
import MzDialog from '../../common/MzDialog'
import RegisterScreen from '../../containers/registerScreen'
import { LOGO } from '../../assets/images'
import { useDispatch, useSelector } from 'react-redux'
import { ROUTE_PATH } from '../../constant/urlConstant'
import { logout } from '../../redux/action/auth/login'
import { fetchGatNoRecord } from '../../redux/action/gatNo'
import PaymentScreen from '../../containers/paymentScreen'
import FooterComponent from '../footer'
import { Link } from 'react-router-dom'
import MzAutoComplete from '../../common/MzForm/MzAutoComplete/index'

const HomeComponent = (props) => {
  const {
    formFieldValueMap,
    isLoading,
    isLoggedIn,
    isPageLevelError,
    fetchDistrictList,
    fetchSubDistrictList,
    fetchVillageList,
    provincesList,
    districtsList,
    subDistrictsList,
    villagesList,
    fetchGatNoList,
    gatNosList,
    isGatNoDetailError
  } = props.homeProps

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    setValue
  } = useForm({
    defaultValues: useMemo(() => {
      return formFieldValueMap
    }, [formFieldValueMap]),
    mode: 'onChange',
    reValidateMode: 'onChange'
  })
  const [visible, setVisible] = useState(true)
  const [visibleLogin, setVisibleLogin] = useState(false)
  const [visibleRegister, setVisibleRegister] = useState(false)
  const [visibleProfile, setVisibleProfile] = useState(false)
  const [visiblePayment, setVisiblePayment] = useState(false)
  const [showToggleButton, setShowToggleButton] = useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [submittedData, setSubmittedData] = useState(null)
  const [visibleSevenEnquiry, setvisibleSevenEnquiry] = useState(false)
  const navigate = useNavigate()

  const user = useSelector((state) => state.userProfileReducer?.user ?? '')
  const dispatch = useDispatch()

  useEffect(() => {
    if (isLoggedIn && isFormSubmitted) {
      onSubmit(submittedData)
      setIsFormSubmitted(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  useEffect(() => {
    if (watch(FORM_FIELDS_NAME.PROVINCE.name)) {
      fetchDistrictList(watch(FORM_FIELDS_NAME.PROVINCE.name))
      setValue(FORM_FIELDS_NAME.DISTRICT.name, '')
      setValue(FORM_FIELDS_NAME.SUB_DISTRICT.name, '')
      setValue(FORM_FIELDS_NAME.VILLAGE.name, '')
      setValue(FORM_FIELDS_NAME.GATNO.name, '')
    }
    // eslint-disable-next-line
  }, [watch(FORM_FIELDS_NAME.PROVINCE.name), fetchDistrictList, setValue])

  useEffect(() => {
    if (watch(FORM_FIELDS_NAME.DISTRICT.name)) {
      fetchSubDistrictList(watch(FORM_FIELDS_NAME.DISTRICT.name))
      setValue(FORM_FIELDS_NAME.SUB_DISTRICT.name, '')
      setValue(FORM_FIELDS_NAME.VILLAGE.name, '')
      setValue(FORM_FIELDS_NAME.GATNO.name, '')
    }
    // eslint-disable-next-line
  }, [watch(FORM_FIELDS_NAME.DISTRICT.name), fetchSubDistrictList, setValue])

  useEffect(() => {
    if (watch(FORM_FIELDS_NAME.SUB_DISTRICT.name)) {
      fetchVillageList(watch(FORM_FIELDS_NAME.SUB_DISTRICT.name))
      setValue(FORM_FIELDS_NAME.VILLAGE.name, '')
      setValue(FORM_FIELDS_NAME.GATNO.name, '')
    }
    // eslint-disable-next-line
  }, [watch(FORM_FIELDS_NAME.SUB_DISTRICT.name), fetchVillageList, setValue])
  useEffect(() => {
    if (watch(FORM_FIELDS_NAME.VILLAGE.name)) {
      fetchGatNoList(watch(FORM_FIELDS_NAME.VILLAGE.name))
      setValue(FORM_FIELDS_NAME.GATNO.name, '')
    }
    // eslint-disable-next-line
  }, [watch(FORM_FIELDS_NAME.VILLAGE.name), fetchGatNoList, setValue])

  const location = useLocation()

  const isMobile = window.matchMedia('(max-width: 768px)').matches
  const shouldShowDropdown =
    isMobile && (location.pathname === '/' || location.pathname === '/home')

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/home') {
      setShowToggleButton(true)
    } else {
      setShowToggleButton(false)
    }
  }, [location.pathname])

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    )
  }
  const onSubmit = async (data) => {
    if (!isLoggedIn) {
      setVisibleLogin(true)
      setIsFormSubmitted(true)
    } else {
      if (plot && data?.gatNo) {
        await dispatch(fetchGatNoRecord(data?.gatNo ?? ''))
      }
      const isMobile = window.innerWidth <= 768
      if (isMobile) {
        setVisible(false)
      }
    }
    setSubmittedData(data)
  }

  useEffect(() => {
    if (isGatNoDetailError === true && isLoggedIn === true) {
      setVisiblePayment(true)
    } else {
      setVisiblePayment(false)
    }
    // eslint-disable-next-line
  }, [isGatNoDetailError])

  const handlePayment = async (status, plot) => {
    setVisiblePayment(status)
    if (status === false) {
      await dispatch(fetchGatNoRecord(plot))
    }
  }
  const toggleChange = () => {
    if (visibleLogin) {
      setVisibleLogin(false)
      setVisibleRegister(true)
    } else if (visibleRegister) {
      setVisibleRegister(false)
      setVisibleLogin(true)
    } else {
      setVisibleRegister(false)
      setVisibleLogin(false)
    }
  }

  const headerTemplate = () => {
    return (
      <>
        {isLoggedIn && (
          <div className=" flex justify-content-center ">
            <img src={LOGO} className="h-2 w-2" alt="Logo" />
          </div>
        )}
      </>
    )
  }

  const start = (
    <Link to="/" style={{ textDecoration: 'none' }}>
      <img
        src={LOGO}
        alt="Logo"
        style={{ height: '50px', cursor: 'pointer' }}
      />
    </Link>
  )
  const items = [
    {
      label: 'Home',
      icon: 'pi pi-home',
      command: () => navigate(ROUTE_PATH.BASE.HOME),
      commandPath: ROUTE_PATH.BASE.HOME
    },
    {
      label: '7/12 & Index2',
      icon: 'pi pi-map',
      // command: () => navigate(ROUTE_PATH.BASE.Enquiry),
      // commandPath: ROUTE_PATH.BASE.Enquiry
      command: () => setvisibleSevenEnquiry(true),
      commandPath: ROUTE_PATH.BASE.HOME
    },
    {
      label: 'About Us',
      icon: 'pi pi-users',
      command: () => navigate(ROUTE_PATH.BASE.ABOUTUS),
      commandPath: ROUTE_PATH.BASE.ABOUTUS
    },

    ...(shouldShowDropdown
      ? [
          {
            label: 'Contact',
            icon: 'pi pi-envelope',
            items: [
              {
                label: 'Contact Us',
                icon: 'pi pi-address-book',
                command: () => navigate(ROUTE_PATH.BASE.CONTACTUS),
                commandPath: ROUTE_PATH.BASE.CONTACTUS
              },
              {
                label: 'Privacy Policy',
                icon: 'pi pi-book ',
                command: () => navigate(ROUTE_PATH.BASE.PRIVACYPOLICY),
                commandPath: ROUTE_PATH.BASE.PRIVACYPOLICY
              },
              {
                label: 'Refund Policy',
                icon: 'pi pi-book',
                command: () => navigate(ROUTE_PATH.BASE.REFUNDPOLICY),
                commandPath: ROUTE_PATH.BASE.REFUNDPOLICY
              },
              {
                label: 'Terms and Conditions',
                icon: 'pi pi-book',
                command: () => navigate(ROUTE_PATH.BASE.TERMSCONDITION),
                commandPath: ROUTE_PATH.BASE.TERMSCONDITION
              },
              {
                label: 'Shipping Policy',
                icon: 'pi pi-book',
                command: () => navigate(ROUTE_PATH.BASE.SHIPPINGPOLICY),
                commandPath: ROUTE_PATH.BASE.SHIPPINGPOLICY
              }
            ]
          }
        ]
      : []),
    ...(isLoggedIn
      ? [
          {
            label: 'Dashboard',
            icon: 'pi pi-th-large',
            command: () => navigate(ROUTE_PATH.DASHBOARD.HOME),
            commandPath: ROUTE_PATH.DASHBOARD.HOME
          },
          {
            label: 'Logout',
            icon: 'pi pi-sign-out',
            command: () => handleLogout(),
            commandPath: ROUTE_PATH.BASE.HOME
          }
        ]
      : [
          {
            label: 'Sign In',
            icon: 'pi pi-sign-in',
            command: () => setVisibleLogin(true),
            commandPath: ROUTE_PATH.BASE.HOME
          }
        ])
  ]

  const handleLogout = () => {
    dispatch(logout())
  }
  const toggleBothChange = () => {
    setVisibleRegister(false)
    setVisibleLogin(false)
  }

  const plot = watch(FORM_FIELDS_NAME.GATNO.name)

  return (
    <div className="h-screen w-screen p-0 ">
      <div className="grid grid-nogutter  mb-8 mt-0 mr-0 ml-0 ">
        <div className="col-12 fixed z-5 ">
          <Menubar
            className=" custom-menubar  justify-content-between"
            model={items}
            start={start}
          />
        </div>

        <div className="col-12 h-full w-full  pt-0 ">
          <div className="h-full  border-0 px-1 w-full relative ">
            <div className="absolute top-0 left-0 z-2">
              {showToggleButton && !visible && (
                <Button
                  icon="pi pi-bars"
                  raised
                  className="m-2 mt-7"
                  onClick={() => setVisible(true)}
                />
              )}
              {showToggleButton && visible && (
                <div className="relative bg-white  fadeinleft animation-ease-out animation-duration-1000 animation-iteration-1 border-round-md shadow-2 mt-8 w-18rem mx-2">
                  <div className="flex justify-content-end absolute top-0 right-0">
                    <Button
                      icon="pi pi-times"
                      rounded
                      small
                      className="p-button-text text-900  "
                      onClick={() => setVisible(false)}
                    />
                  </div>
                  <div className="p-3 mt-1">
                    <form
                      style={{ fontFamily: 'Poppins' }}
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="card w-full ">
                        <div className="grid grid-nogutter gap-2">
                          <div className="col-12">
                            <MzAutoComplete
                              control={control}
                              name={FORM_FIELDS_NAME.PROVINCE.name}
                              label={FORM_FIELDS_NAME.PROVINCE.label}
                              optionLabel={
                                FORM_FIELDS_NAME.PROVINCE.optionLabel
                              }
                              optionValue={
                                FORM_FIELDS_NAME.PROVINCE.optionValue
                              }
                              placeholder={
                                FORM_FIELDS_NAME.PROVINCE.placeholder
                              }
                              isError={!!errors[FORM_FIELDS_NAME.PROVINCE.name]}
                              errorMsg={getFormErrorMessage(
                                FORM_FIELDS_NAME.PROVINCE.name
                              )}
                              rules={FORM_FIELDS_NAME.PROVINCE.rules}
                              suggestions={provincesList ?? []}
                              dropdown
                            />
                          </div>
                          <div className=" col-12 ">
                            <MzAutoComplete
                              control={control}
                              name={FORM_FIELDS_NAME.DISTRICT.name}
                              label={FORM_FIELDS_NAME.DISTRICT.label}
                              optionLabel={
                                FORM_FIELDS_NAME.DISTRICT.optionLabel
                              }
                              optionValue={
                                FORM_FIELDS_NAME.DISTRICT.optionValue
                              }
                              placeholder={
                                FORM_FIELDS_NAME.DISTRICT.placeholder
                              }
                              isError={!!errors[FORM_FIELDS_NAME.DISTRICT.name]}
                              errorMsg={getFormErrorMessage(
                                FORM_FIELDS_NAME.DISTRICT.name
                              )}
                              rules={FORM_FIELDS_NAME.DISTRICT.rules}
                              suggestions={districtsList ?? []}
                              dropdown
                            />
                          </div>
                          <div className=" col-12 ">
                            <MzAutoComplete
                              control={control}
                              name={FORM_FIELDS_NAME.SUB_DISTRICT.name}
                              label={FORM_FIELDS_NAME.SUB_DISTRICT.label}
                              optionLabel={
                                FORM_FIELDS_NAME.SUB_DISTRICT.optionLabel
                              }
                              optionValue={
                                FORM_FIELDS_NAME.SUB_DISTRICT.optionValue
                              }
                              placeholder={
                                FORM_FIELDS_NAME.SUB_DISTRICT.placeholder
                              }
                              isError={
                                !!errors[FORM_FIELDS_NAME.SUB_DISTRICT.name]
                              }
                              errorMsg={getFormErrorMessage(
                                FORM_FIELDS_NAME.SUB_DISTRICT.name
                              )}
                              rules={FORM_FIELDS_NAME.SUB_DISTRICT.rules}
                              suggestions={subDistrictsList ?? []}
                              dropdown
                            />
                          </div>
                          <div className=" col-12 ">
                            <MzAutoComplete
                              control={control}
                              name={FORM_FIELDS_NAME.VILLAGE.name}
                              label={FORM_FIELDS_NAME.VILLAGE.label}
                              optionLabel={FORM_FIELDS_NAME.VILLAGE.optionLabel}
                              optionValue={FORM_FIELDS_NAME.VILLAGE.optionValue}
                              placeholder={FORM_FIELDS_NAME.VILLAGE.placeholder}
                              isError={!!errors[FORM_FIELDS_NAME.VILLAGE.name]}
                              errorMsg={getFormErrorMessage(
                                FORM_FIELDS_NAME.VILLAGE.name
                              )}
                              rules={FORM_FIELDS_NAME.VILLAGE.rules}
                              suggestions={villagesList ?? []}
                              dropdown
                            />
                          </div>
                          <div className=" col-12 ">
                            <MzAutoComplete
                              control={control}
                              name={FORM_FIELDS_NAME.GATNO.name}
                              label={FORM_FIELDS_NAME.GATNO.label}
                              optionLabel={FORM_FIELDS_NAME.GATNO.optionLabel}
                              optionValue={FORM_FIELDS_NAME.GATNO.optionValue}
                              placeholder={FORM_FIELDS_NAME.GATNO.placeholder}
                              isError={!!errors[FORM_FIELDS_NAME.GATNO.name]}
                              errorMsg={getFormErrorMessage(
                                FORM_FIELDS_NAME.GATNO.name
                              )}
                              rules={FORM_FIELDS_NAME.GATNO.rules}
                              suggestions={gatNosList ?? []}
                              dropdown
                            />
                          </div>
                          <div className="col-12 mt-3 ">
                            <Button
                              type="submit"
                              label="Submit"
                              className="w-full"
                              disabled={isLoading || isPageLevelError}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
            <Outlet />
          </div>
        </div>
      </div>

      <Dialog
        visible={visibleProfile}
        onHide={() => setVisibleProfile(false)}
        position="top-right"
        className="mt-7 mb-7 mx-1 md:mx-2 w-17rem "
        header={headerTemplate()}
      >
        <div
          className=" text-center px-2 h-full  flex flex-column "
          style={{ borderRadius: '6px' }}
        >
          <div className="text-900 font-medium text-xl mb-2">
            {user?.name ?? ''}
          </div>

          <div className="text-600">{user?.email ?? ''}</div>
          <div className="text-600 mb-2">{user?.mobile ?? ''}</div>

          <ul className="list-none p-0 m-0 flex-grow-1"></ul>
          <hr className="mx-0 mt-2  border-top-1 border-none surface-border " />
        </div>
      </Dialog>
      <MzDialog
        visible={visiblePayment}
        onHide={() => setVisiblePayment(false)}
        children={<PaymentScreen plot={plot} handlePayment={handlePayment} />}
      ></MzDialog>
      <MzDialog
        children={
          <>
            {visibleLogin && (
              <LoginScreen
                toggleChange={toggleChange}
                setVisibleLogin={setVisibleLogin}
                toggleBothChange={toggleBothChange}
              />
            )}
            {visibleRegister && <RegisterScreen toggleChange={toggleChange} />}
          </>
        }
        visible={
          visibleLogin
            ? visibleLogin
            : visibleRegister
              ? visibleRegister
              : false
        }
        onHide={() => {
          toggleBothChange()
        }}
      ></MzDialog>

      <MzDialog
        onHide={() => setvisibleSevenEnquiry(false)}
        visible={visibleSevenEnquiry}
        children={
          <AddEditSevenEnquiryScreen
            setvisibleSevenEnquiry={setvisibleSevenEnquiry}
          />
        }
      ></MzDialog>

      <FooterComponent />
    </div>
  )
}

export default HomeComponent

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import VillageComponent from '../../components/village/index'
import { MzToast, TOAST_SEVERITY } from '../../common/MzToast'
import { ProgressBar } from 'primereact/progressbar'
import { useNavigate } from 'react-router-dom'
import { fetchProvinceList } from '../../redux/action/province'
import { fetchDistrictList } from '../../redux/action/district'
import { fetchSubDistrictList } from '../../redux/action/subDistrict'
import {
  fetchVillageList,
  updateVillageRecord
} from '../../redux/action/village'

const VillageScreen = (props) => {
  const {
    isEditVillageSuccess,
    isEditVillageError,
    isEdit,
    isLoading,
    formFieldValueMap,
    isPageLevelError,
    fetchVillageRecord,
    provincesList,
    districtsList,
    subDistrictsList,
    villagesList,
    fetchProvinceList,
    fetchDistrictList,
    fetchSubDistrictList,
    fetchVillageList,
    updateVillageRecord
  } = props

  const navigate = useNavigate()

  useEffect(() => {
    fetchProvinceList()
  }, [fetchProvinceList])

  const getToastProps = () => {
    if (isEditVillageSuccess) {
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle: 'Village Updated Successfully',
        shouldShowToast: true
      }
    }

    if (isEditVillageError) {
      const toastTitle = 'Error while updating Village'

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }

  const addVillageProps = {
    formFieldValueMap,
    isPageLevelError,
    isLoading,
    isEdit,

    isEditVillageSuccess,

    fetchVillageList,
    fetchVillageRecord,
    updateVillageRecord,
    navigate,
    fetchDistrictList,
    fetchSubDistrictList,
    provincesList,
    districtsList,
    subDistrictsList,
    villagesList
  }

  return (
    <>
      {isLoading && (
        <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
      )}
      <MzToast {...getToastProps()} />
      <VillageComponent addVillageProps={addVillageProps} />
    </>
  )
}

const mapDispatchToProps = {
  fetchProvinceList,
  fetchDistrictList,
  fetchSubDistrictList,
  fetchVillageList,
  updateVillageRecord
}

const mapStateToProps = (state) => ({
  formFieldValueMap: state.villageReducer.formFieldValueMap,
  isPageLevelError: state.villageReducer.isPageLevelError,
  isLoading: state.villageReducer.isLoading,
  error: state.villageReducer.error,
  isEditVillageSuccess: state.villageReducer.isEditVillageSuccess,
  isEditVillageError: state.villageReducer.isEditVillageError,
  provincesList: state.provinceReducer.provincesList,
  districtsList: state.districtReducer.districtsList,
  subDistrictsList: state.subDistrictReducer.subDistrictsList,
  villagesList: state.villageReducer.villagesList
})

export default connect(mapStateToProps, mapDispatchToProps)(VillageScreen)

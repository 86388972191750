import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import AddLinkComponent from '../../../components/link/addlink'
import { MzToast, TOAST_SEVERITY } from '../../../common/MzToast'
import { ProgressBar } from 'primereact/progressbar'
import {
  createLinkRecord,
  fetchLinkList,
  fetchLinkRecord,
  initLink,
  updateLinkRecord
} from '../../../redux/action/link'
import { USER_ROLE } from '../../../constant/actionTypes/role'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../../constant/urlConstant'
import { fetchProvinceList } from '../../../redux/action/province'
import { fetchDistrictList } from '../../../redux/action/district'
import { fetchSubDistrictList } from '../../../redux/action/subDistrict'
import {
  fetchVillageList,
  fetchVillageListBySalesPerson
} from '../../../redux/action/village'
import { fetchGatNoList } from '../../../redux/action/gatNo'
import { fetchUserList, initUser } from '../../../redux/action/user'

const AddLinkScreen = (props) => {
  const {
    isCreateLinkSuccess,
    isCreateLinkError,
    isLinkDetailSuccess,
    isLinkDetailError,
    isEditLinkSuccess,
    isEditLinkError,
    isEdit,
    isLoading,
    error,
    createLinkRecord,
    formFieldValueMap,
    isPageLevelError,
    user,
    userRole,
    initLink,
    fetchLinkRecord,
    updateLinkRecord,
    fetchDistrictList,
    fetchSubDistrictList,
    fetchVillageList,
    provincesList,
    districtsList,
    subDistrictsList,
    villagesList,
    fetchProvinceList,
    fetchGatNoList,
    gatNosList,
    userList,
    fetchUserList,
    initUser,
    fetchVillageListBySalesPerson
  } = props
   
  useEffect(() => {
    initLink()
    initUser()
    const paylaod={
      pageNumber: 0,
      pageSize:2000
    }
    fetchUserList(paylaod)
    if (userRole === USER_ROLE.SUPER_ADMIN) {
      fetchProvinceList()
    } else if (userRole === USER_ROLE.SELLER) {
      fetchVillageListBySalesPerson()
    } else {
      //
    }
    // eslint-disable-next-line
  }, [userRole])

  const history = useNavigate()
  // const [rowData, setRowData]= useState({})

  const getToastProps = () => {
    if (isCreateLinkSuccess || isEditLinkSuccess) {
      const toastTitle = isEdit
        ? 'Link Updated Successfully'
        : 'Link Created Successfully'
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }

    if (isCreateLinkError || isLinkDetailError || isEditLinkError) {
      let toastTitle = error ??'Error while Creating Link'
      if (isEditLinkError) {
        toastTitle = 'Error while updating Link'
      } else if (isLinkDetailError) {
        toastTitle =
          'Error while performing operation. Please refresh your browser'
      }

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }


  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }
  const navigatetoLink = () => {
    history(ROUTE_PATH.LINK.HOME)
  }
  const addLinkProps = {
    createLinkRecord,
    formFieldValueMap,
    isPageLevelError,
    isLoading,
    user,
    isEdit,
    userRole,
    isCreateLinkSuccess,
    isEditLinkSuccess,
    isLinkDetailSuccess,
    fetchLinkList,
    fetchLinkRecord,
    updateLinkRecord,
    navigatetoLink,
    fetchDistrictList,
    fetchSubDistrictList,
    fetchVillageList,
    provincesList,
    districtsList,
    fetchUserList,
    subDistrictsList,
    villagesList,
    fetchGatNoList,
    gatNosList,
    userList
  }

  return (
    <>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />
      <AddLinkComponent addLinkProps={addLinkProps} />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    initLink: () => dispatch(initLink()),
    initUser: () => dispatch(initUser()),
    fetchUserList: (payload) => dispatch(fetchUserList(payload)),
    createLinkRecord: (payload) => dispatch(createLinkRecord(payload)),
    fetchLinkRecord: (payload) => dispatch(fetchLinkRecord(payload)),
    updateLinkRecord: (payload) => dispatch(updateLinkRecord(payload)),
    fetchProvinceList: () => dispatch(fetchProvinceList()),
    fetchDistrictList: (payload) => dispatch(fetchDistrictList(payload)),
    fetchSubDistrictList: (payload) => dispatch(fetchSubDistrictList(payload)),
    fetchVillageList: (payload) => dispatch(fetchVillageList(payload)),
    fetchGatNoList: (payload) => dispatch(fetchGatNoList(payload)),
    fetchVillageListBySalesPerson: () =>
      dispatch(fetchVillageListBySalesPerson())
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    formFieldValueMap: selectFormFieldValueMap(state.linkReducer),
    isPageLevelError: state.linkReducer.isPageLevelError,
    isLoading: state.linkReducer.isLoading,
    error: state.linkReducer.error,
    isCreateLinkSuccess: state.linkReducer.isCreateLinkSuccess,
    isCreateLinkError: state.linkReducer.isCreateLinkError,
    isEditLinkSuccess: state.linkReducer.isEditLinkSuccess,
    isEditLinkError: state.linkReducer.isEditLinkError,
    isLinkDetailError: state.linkReducer.isLinkDetailError,
    isLinkDetailSuccess: state.linkReducer.isLinkDetailSuccess,
    user: state.userProfileReducer.user,
    userRole: state.loginReducer.userRole,
    provincesList: state.provinceReducer.provincesList,
    districtsList: state.districtReducer.districtsList,
    subDistrictsList: state.subDistrictReducer.subDistrictsList,
    villagesList: state.villageReducer.villagesList,
    gatNosList: state.gatNoReducer.gatNosList,
    userList: state.userReducer.userList
  }
}

const selectFormFieldValueMap = (linkReducer) => {
  return linkReducer.formFieldValueMap
}

export default connect(mapStateToProps, mapDispatchToProps)(AddLinkScreen)

import React, { useEffect, useState } from 'react'
import MzTable from '../../common/MzTable'
import ErrorPage from '../../common/Error'
import { BreadCrumb } from 'primereact/breadcrumb'
import { USER_ROLE } from '../../constant/actionTypes/role'
import { Button } from 'primereact/button'
import { ROUTE_PATH } from '../../constant/urlConstant'
import { useNavigate } from 'react-router-dom'
import { AutoComplete } from 'primereact/autocomplete'
import moment from 'moment'

const LinkComponent = (props) => {
  const {
    fetchLinkList,
    linksList,
    isPageLevelError,
    linkTableData,
    handleOnReadRecord,
    handleOnDeleteRecord,
    handleOnEditRecord,
    isLoading,
    userRole,
    handleOnCreatedRecord,
    handleOnDownloadRecord
  } = props.linkProps

  const [paginationInfo, setPaginationInfo] = useState({
    pageSize: 10,
    pageNumber: 0,
    totalRecords: 0,
    totalPages: 0
  })
  const [query, setQuery] = useState('')
  const history = useNavigate()

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (query.length > 3 || query === '') {
        const payload = {
          query,
          pageNumber: 0,
          pageSize: 10
        }
        fetchLinkList(payload)
      }
    }, 300)
    return () => clearTimeout(delayDebounceFn)
  }, [query, fetchLinkList])

  const loadLazyData = (event) => {
    const { pageNumber, pageSize } = event
    setPaginationInfo((prev) => ({
      ...prev,
      pageNumber,
      pageSize
    }))

    fetchLinkList({ query: query, pageNumber, pageSize })
  }

  const shouldRenderFullPageError = () => isPageLevelError
  const shouldRenderUserTransactionList = () =>
    linkTableData?.tableData?.columns?.length > 0
  const shouldRenderNotFoundView = () =>
    !shouldRenderFullPageError && !shouldRenderUserTransactionList

  const filters = {
    global: { value: null },
    userName: { filterKey: 'username', value: null },
    email: { filterKey: 'email', value: null }
  }

  const shouldRenderActiveColumn = () => userRole !== USER_ROLE.USER

  let columns = linkTableData?.tableData?.columns

  if (shouldRenderActiveColumn()) {
    columns = [
      ...columns,
      {
        colLabel: 'Active',
        dataKey: 'active',
        extraProps: {
          isSortable: false,
          isDateField: false,
          shouldFilter: false,
          hidden: false,
          showFilterMenu: false,
          filterPlaceholder: 'Enter Active Status'
        }
      }
    ]
  }

  if (userRole === USER_ROLE.USER) {
    columns = columns.filter(
      (column) =>
        !['user.firstName', 'user.lastName', 'user.mobile'].includes(
          column.dataKey
        )
    )
  }
  const screenPermission =
    userRole === USER_ROLE.SUPER_ADMIN
      ? {
          ...linkTableData?.tableData?.screenPermission,
          update: true,
          download: true
        }
      : {
          ...linkTableData?.tableData?.screenPermission,
          update: false,
          download: false
        }

  const tableProps = {
    value:
      linksList?.content?.map((link) => ({
        ...link,
        createdAt: moment(link.createdAt).format('DD/MMM/YYYY HH:mm ')
      })) ?? [],
    loading: isLoading,
    columns:
      userRole === USER_ROLE.SUPER_ADMIN
        ? columns
        : columns.filter((col) => col.colLabel !== 'Usage'),
    paginationInfo: {
      ...paginationInfo,
      totalRecords: linksList?.totalElements ?? 0
    },
    screenPermission,
    loadLazyData,
    emptyMessage: 'No Link Record Found',
    filters,
    sortField: null,
    showMoreAction: false,
    onReadRecord: handleOnReadRecord,
    onEditRecord: handleOnEditRecord,
    onDeleteRecord: handleOnDeleteRecord,
    onDownloadRecord: handleOnDownloadRecord
  }

  const handleToCreateLink = () => {
    handleOnCreatedRecord()
  }

  const renderPageHeaderSection = () => {
    const breadCrumbItems = [
      { label: 'Link', command: () => history(ROUTE_PATH.LINK.HOME) }
    ]
    const home = {
      icon: 'pi pi-home',
      command: () => history(ROUTE_PATH.DASHBOARD.HOME)
    }

    return (
      <div className="flex justify-content-between align-items-center border-1 border-200 border-round bg-white">
        <BreadCrumb model={breadCrumbItems} home={home} className="border-0" />
        <div className="mr-2 flex justify-content-between h-full align-items-center gap-3">
          <div className="flex-grow-1" style={{ maxWidth: '250px' }}>
            <AutoComplete
              value={query}
              field="label"
              onChange={(e) => setQuery(e.value)}
              dropdown
              placeholder={
                userRole === USER_ROLE.USER
                  ? 'Search By Plot...'
                  : 'Search By User...'
              }
              className="w-full"
            />
          </div>
          <div>
            {USER_ROLE.USER !== userRole && (
              <Button onClick={handleToCreateLink} className="py-2">
                Create Link
              </Button>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {renderPageHeaderSection()}
      {shouldRenderFullPageError() && (
        <div>
          <ErrorPage />
        </div>
      )}
      {shouldRenderUserTransactionList() && (
        <div className="border-1 border-200 border-round mt-3 p-2 bg-white">
          <MzTable {...tableProps} />
        </div>
      )}
      {shouldRenderNotFoundView() && <div>No record to show</div>}
    </div>
  )
}

export default LinkComponent

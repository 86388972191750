export const ROUTE_PATH = {
  BASE: {
    HOME: '/',
    REGISTER: '/signup',
    ABOUTUS: '/about-us',
    Enquiry: '/seven-enquiry',
    CONTACTUS: '/contact-us',
    PRIVACYPOLICY: '/privacy-policy',
    REFUNDPOLICY: '/refund-policy',
    TERMSCONDITION: '/terms-conditions',
    SHIPPINGPOLICY: '/shipping-policy',
    RESET_PASSWORD: '/reset-password',
    CHANGE_PASSWORD: '/change-password'
  },
  DASHBOARD: {
    HOME: '/dashbaord',
    TEMP: '/:id'
  },

  COMPANY: {
    HOME: '/dashbaord/company',
    ADD: '/dashbaord/company/add',
    EDIT: '/dashbaord/company/edit/:id'
  },
  // USER: {
  //   HOME: "/dashbaord/user",
  //   ADD: "/dashbaord/user/add",
  //   EDIT: "/user/edit/:id",
  // },
  LINK: {
    HOME: '/dashbaord/links',
    ADD: '/dashbaord/links/add',
    EDIT: '/dashbaord/links/edit/:id',
    UPDATE: '/dashbaord/links/update-plot/:id'
  },
  USER: {
    HOME: '/dashbaord/user',
    EDIT: '/dashbaord/update-user/:id',
    ADD: '/dashbaord/add-user'
  },
  ACCESS: {
    HOME: '/dashbaord/access',
    EDIT: '/dashbaord/update-acess/:id',
    ADD: '/dashbaord/add-access'
  },
  SEVENENQUIRY: {
    FetchSevenEnquiry: 'fetch-sevenEnquiry'
  },
  DISTRICT: {
    HOME: '/dashbaord/district',
    EDIT: '/dashbaord/update-district/:id',
    ADD: '/dashbaord/add-district'
  },
  SUB_DISTRICT: {
    HOME: '/dashbaord/sub-district',
    EDIT: '/dashbaord/update-sub-district/:id',
    ADD: '/dashbaord/add-sub-district'
  },
  VILLAGE: {
    HOME: '/dashbaord/village',
    EDIT: '/dashbaord/update-village/:id',
    ADD: '/dashbaord/add-village'
  },
  // ACCESS: {
  //   HOME: "/dashbaord/access",
  //   ADD: "/dashbaord/access/add",
  //   EDIT: "/dashbaord/access/edit/:id",
  // },
  LOCATION: {
    HOME: '/dashbaord/location',
    HOME1: '/dashbaord/location/:id',
    ADD: '/dashbaord/location/add',
    EDIT: '/dashbaord/location/edit/:id'
  },

  FILE_UPLOAD: {
    HOME: '/dashbaord/file-upload',
    ADD: '/dashbaord/file-upload/add',
    EDIT: '/dashbaord/file-upload/edit/:id'
  },
  // SETING: {
  //   HOME: '/dashbaord/setting'
  // },
  BUSSINESS_CARD: {
    HOME: '/user-digital-card/:id'
  },
  ENQUIRY: {
    HOME: '/dashbaord/enquiry'
  }
}

export const API_PATH = {
  AUTH: {
    LOGIN: '/authenticate',
    REGISTER: '/account-sign-up'
  },

  USER_PROFILE: {
    FETCH: '/user/details'
  },

  TENANT: {
    FETCH: '/tenants',
    ADD: '/tenants',
    EDIT: '/tenants',
    DELETE: '/tenants'
  },
  SEVEN_ENQUIRY: {
    ADD: '/enquiry-us',
    FETCH: '/enquiry'
  },
  LINKS: {
    FETCH: '/linkss',
    FETCH_BY_TENANT: '/linkss/tenant',
    ADD: '/linkss',
    EDIT: '/linkss',
    DELETE: '/linkss',
    COUNT: '/linkss/count'
  },
  FILE_UPLOAD: {
    FETCH: '/file-upload',
    FETCH_BY_TENANT: '/file-upload-tenant',
    ADD: '/plot/upload',
    EDIT: '/file-upload',
    DELETE: '/file-upload',
    COUNT: '/file-upload/count'
  },
  // ACCESS: {
  //   FETCH: "/access",
  //   FETCH_BY_TENANT: "/access-tenant",
  //   ADD: "/access",
  //   EDIT: "/access",
  //   DELETE: "/access",
  // },
  INTEREST: {
    FETCH: '/interest',
    ADD: '/interest'
  },
  ANALYTICS: {
    FETCH: '/analytics',
    FETCH_BY_TENANT: '/analytics-tenant',
    FETCH_BY_USER: '/analytics-user',
    FETCH_BY_USER_AND_TENANT: '/analytics-user-tenant'
  },

  USER: {
    FETCH: '/user',
    ADD: '/user',
    EDIT: '/user',
    DELETE: '/user',
    COUNT: '/user/count'
  },

  USER_ACCESS: {
    FETCH: '/user-access/access',
    FETCH_BY_ID: '/user-access',
    ADD: '/user-access',
    EDIT: '/user-access',
    DELETE: '/user-access',
    COUNT: '/user-access/count'
  },
  PROVINCE: {
    FETCH: '/state',
    ADD: '/province',
    EDIT: '/province',
    DELETE: '/province',
    COUNT: '/province/count'
  },
  DISTRICT: {
    FETCH: '/district',
    FETCH_BY_STATE: '/district/state',
    ADD: '/district',
    EDIT: '/district',
    DELETE: '/district',
    COUNT: '/district/count'
  },
  SUB_DISTRICT: {
    FETCH: '/taluka',
    FETCH_BY_DISTRICT: '/taluka/district',
    ADD: '/taluka',
    EDIT: '/taluka',
    DELETE: '/taluka',
    COUNT: '/taluka/count'
  },
  VILLAGE: {
    FETCH: '/village',
    FETCH_BY_SUB_DISTRICT: '/village/taluka',
    FETCH_BY_SALES_PERSON: '/village/role',
    ADD: '/village',
    EDIT: '/village',
    DELETE: '/village',
    COUNT: '/village/count'
  },
  GATNO: {
    FETCH: '/plot',
    FETCH_BY_URL: '/user-access/plot',
    FETCH_BY_VILLAGE: '/plot/village',
    ADD: '/plot',
    EDIT: '/plot',
    DELETE: '/plot',
    COUNT: '/plot/count'
  },
  LINK: {
    FETCH: '/user-access/link',
    FETCH_BY_ID: '/user-access',
    ADD: '/user-access',
    EDIT: '/user-access/update-link',
    UPDATE: '/user-access/plot-coordinates',
    DELETE: '/user-access',
    COUNT: '/user-access/count'
  },
  PAYMENT: {
    FETCH_PLAN: '/plan'
  },
  CONTACTUS: {
    ADD: '/contact-us',
    FETCH: '/contact'
  }
}

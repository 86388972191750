import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import SubDistrictComponent from '../../components/subDistrict/index'
import { MzToast, TOAST_SEVERITY } from '../../common/MzToast'
import { ProgressBar } from 'primereact/progressbar'
import { useNavigate } from 'react-router-dom'
import { fetchProvinceList } from '../../redux/action/province'
import { fetchDistrictList } from '../../redux/action/district'
import {
  fetchSubDistrictList,
  updateSubDistrictRecord
} from '../../redux/action/subDistrict'

const SubDistrictScreen = (props) => {
  const {
    isEditSubDistrictSuccess,
    isEditSubDistrictError,
    isEdit,
    isLoading,
    formFieldValueMap,
    isPageLevelError,
    fetchSubDistrictRecord,
    provincesList,
    districtsList,
    fetchProvinceList,
    fetchDistrictList,
    subDistrictsList,
    updateSubDistrictRecord,
    fetchSubDistrictList
  } = props

  const navigate = useNavigate()

  useEffect(() => {
    fetchProvinceList()
  }, [fetchProvinceList])

  const getToastProps = () => {
    if (isEditSubDistrictSuccess) {
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle: 'SubDistrict Updated Successfully',
        shouldShowToast: true
      }
    }

    if (isEditSubDistrictError) {
      const toastTitle = 'Error while updating SubDistrict'

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }

  const addSubDistrictProps = {
    formFieldValueMap,
    isPageLevelError,
    isLoading,
    isEdit,
    isEditSubDistrictSuccess,
    fetchSubDistrictList,
    fetchSubDistrictRecord,
    updateSubDistrictRecord,
    navigate,
    fetchDistrictList,
    provincesList,
    districtsList,
    subDistrictsList
  }

  return (
    <>
      {isLoading && (
        <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
      )}
      <MzToast {...getToastProps()} />
      <SubDistrictComponent addSubDistrictProps={addSubDistrictProps} />
    </>
  )
}

const mapDispatchToProps = {
  fetchProvinceList,
  fetchDistrictList,
  fetchSubDistrictList,
  updateSubDistrictRecord
}

const mapStateToProps = (state) => ({
  formFieldValueMap: state.subDistrictReducer.formFieldValueMap,
  isPageLevelError: state.subDistrictReducer.isPageLevelError,
  isLoading: state.subDistrictReducer.isLoading,
  error: state.subDistrictReducer.error,
  isEditSubDistrictSuccess: state.subDistrictReducer.isEditSubDistrictSuccess,
  isEditSubDistrictError: state.subDistrictReducer.isEditSubDistrictError,
  provincesList: state.provinceReducer.provincesList,
  districtsList: state.districtReducer.districtsList,
  subDistrictsList: state.subDistrictReducer.subDistrictsList
})

export default connect(mapStateToProps, mapDispatchToProps)(SubDistrictScreen)

export const FORM_FIELDS_NAME = {
  FIRST_NAME: {
    name: 'fname',
    label: 'First Name',
    type: 'text',
    placeholder: 'Enter your first name',
    rules: {
      required: 'First name is required',
      minLength: {
        value: 3,
        message: 'First name must be at least 3 characters'
      },
      maxLength: {
        value: 20,
        message: 'First name cannot exceed 20 characters'
      }
    }
  },
  LAST_NAME: {
    name: 'lname',
    label: 'Last Name',
    type: 'text',
    placeholder: 'Enter your last name',
    rules: {
      required: 'Last name is required',
      minLength: {
        value: 3,
        message: 'Last name must be at least 3 characters'
      },
      maxLength: {
        value: 20,
        message: 'Last name cannot exceed 20 characters'
      }
    }
  },
  SURVEYNO: {
    name: 'description',
    label: 'Enter Survey No',
    type: 'text',
    placeholder: 'Enter a Survey No',
    // rules: {
    //   required: 'Survey No is required'
    // }
  },

  MOBILE: {
    name: 'mobile',
    label: 'Mobile Number',
    type: 'tel',
    placeholder: 'Enter your mobile number',
    rules: {
      required: 'Mobile number is required',
      pattern: {
        value: /^[0-9]{12}$/,
        message: 'Invalid mobile number'
      }
    }
  },
  EMAIL: {
    name: 'email',
    label: 'Email',
    type: 'email',
    placeholder: 'Enter your email',
    rules: {
      required: 'Email is required',
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        message: 'Invalid email address'
      }
    }
  },
  ENQUIRY_TYPE: {
    name: 'enquiryType',
    label: 'Select Enquiry Type',
    type: 'select',
    placeholder: 'Select an enquiry type',
    options: [
      { value: 'SEVEN_TWELVE', label: '7/12' },
      { value: 'INDEX1', label: 'Index2' },
      { value: 'BOTH', label: 'Both' }
    ],
    rules: {
      required: 'Enquiry type is required'
    }
  },
  PROVINCE: {
    name: 'state',
    label: 'State',
    type: 'dropdown',
    optionLabel: 'name',
    optionValue: 'id',
    rules: {
      required: 'State is required'
    },
    options: [],
    placeholder: 'Select a state'
  },
  DISTRICT: {
    name: 'district',
    label: 'District',
    type: 'dropdown',
    optionLabel: 'name',
    optionValue: 'id',
    rules: {
      required: 'District is required'
    },
    options: [],
    placeholder: 'Select a district'
  },
  SUB_DISTRICT: {
    name: 'taluka',
    label: 'SubDistrict',
    type: 'dropdown',
    optionLabel: 'name',
    optionValue: 'id',
    rules: {
      required: 'SubDistrict is required'
    },
    options: [],
    placeholder: 'Select a subdistrict'
  },
  VILLAGE: {
    name: 'village',
    label: 'Village',
    type: 'dropdown',
    optionLabel: 'name',
    optionValue: 'id',
    rules: {
      required: 'Village is required'
    },
    options: [],
    placeholder: 'Select a Village'
  },

  GATNO: {
    name: 'gatNo',
    label: 'GatNo',
    type: 'dropdown',
    optionLabel: 'plotNumber',
    optionValue: 'id',
    rules: {
      required: 'GatNo is required'
    },
    options: [],
    placeholder: 'Select a gatNo'
  }
}

import SevenEnquiryComponent from '../../components/sevenEnquiry'
import EnquirySevenTableData from './data.json'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import {
  fetchSevenEnquiryList,
  initSevenEnquiry
} from '../../redux/action/sevenEnquiry'
const SevenEnquiryScreen = (props) => {
  const [visibleSevenEnquiry, setvisibleSevenEnquiry] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState(null)
  const {
    fetchSevenEnquiryList,
    isPageLevelError,
    isLoading,
    userRole,
    initSevenEnquiry,
    isDeleteSevenEnquirySuccess,
    user,
    handleOnDeleteRecord,
    handleOnDownloadRecord,

    error,
    sevenEnquiryList
  } = props

  const handleOnReadRecord = (data) => {
    setSelectedRowData(data)
    // setvisibleSevenEnquiry(true)
  }

  const EnquirySevenProps = {
    EnquirySevenTableData,
    fetchSevenEnquiryList,
    isPageLevelError,
    userRole,
    visibleSevenEnquiry,
    setvisibleSevenEnquiry,
    initSevenEnquiry,
    user,
    isLoading,
    selectedRowData,
    handleOnReadRecord,
    handleOnDeleteRecord,
    handleOnDownloadRecord,
    error,
    sevenEnquiryList
  }

  useEffect(() => {
    initSevenEnquiry()
    const paylaod = {
      pageNumber: 0,
      pageSize: 10
    }
    fetchSevenEnquiryList(paylaod)
    // eslint-disable-next-line
  }, [isDeleteSevenEnquirySuccess])
  return (
    <div>
      <SevenEnquiryComponent EnquirySevenProps={EnquirySevenProps} />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSevenEnquiryList: (payload) =>
      dispatch(fetchSevenEnquiryList(payload)),
    initSevenEnquiry: () => dispatch(initSevenEnquiry())
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isPageLevelError: state.sevenEnquiryReducer?.isPageLevelError,
    isLoading: state.sevenEnquiryReducer?.isLoading,
    error: state.sevenEnquiryReducer?.error,
    userRole: state.loginReducer?.userRole,
    sevenEnquiryList: state.sevenEnquiryReducer?.sevenEnquiryList,
    isDeleteSevenEnquirySuccess:
      state.sevenEnquiryReducer?.isDeleteSevenEnquirySuccess,
    isDeleteSevenEnquiryError:
      state.sevenEnquiryReducer?.isDeleteSevenEnquiryError
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SevenEnquiryScreen)

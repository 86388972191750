import React, { useEffect } from 'react'
import { fetchProvinceList } from '../../../redux/action/province'
import { fetchDistrictList } from '../../../redux/action/district'
import { fetchSubDistrictList } from '../../../redux/action/subDistrict'
import {
  createSevenEnquiryRecord,
  initSevenEnquiry
} from '../../../redux/action/sevenEnquiry'
import { MzToast, TOAST_SEVERITY } from '../../../common/MzToast'
import { fetchVillageList } from '../../../redux/action/village'
import { fetchGatNoList } from '../../../redux/action/gatNo'

import AddEditSevenEnquiryComponent from '../../../components/sevenEnquiry/addEditSevenEnquiry'
import { connect } from 'react-redux'

function AddEditSevenEnquiryScreen(props) {
  const {
    fetchDistrictList,
    fetchSubDistrictList,
    fetchVillageList,
    provincesList,
    districtsList,
    subDistrictsList,
    villagesList,
    fetchProvinceList,
    formFieldValueMap,
    createSevenEnquiryRecord,
    isPageLevelError,
    fetchSevenEnquiryList,
    isCreateSevenEnquirySuccess,
    isCreateSevenEnquiryError,
    isSevenEnquiryDetailError,
    fetchGatNoList,
    isSevenEnquiryDetailSuccess,
    gatNosList,
    setvisibleSevenEnquiry,
    initSevenEnquiry
  } = props

  useEffect(() => {
    initSevenEnquiry()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getToastProps = () => {
    if (isCreateSevenEnquirySuccess) {
      const toastTitle = 'Enquiry Created Successfully'
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true,
        life: 1500
      }
    }

    if (isCreateSevenEnquiryError) {
      let toastTitle = 'Error while Creating Enquiry'
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true,
        life: 1500
      }
    }
  }

  const SevenEnquiryProps = {
    fetchDistrictList,
    fetchSubDistrictList,
    fetchVillageList,
    provincesList,
    districtsList,
    subDistrictsList,
    villagesList,
    fetchProvinceList,
    formFieldValueMap,
    createSevenEnquiryRecord,
    isPageLevelError,
    fetchSevenEnquiryList,
    isCreateSevenEnquirySuccess,
    isCreateSevenEnquiryError,
    isSevenEnquiryDetailError,
    fetchGatNoList,
    isSevenEnquiryDetailSuccess,
    setvisibleSevenEnquiry,
    gatNosList
  }
  return (
    <div>
      <MzToast {...getToastProps()} />
      <AddEditSevenEnquiryComponent SevenEnquiryProps={SevenEnquiryProps} />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProvinceList: () => dispatch(fetchProvinceList()),
    fetchDistrictList: (payload) => dispatch(fetchDistrictList(payload)),
    fetchSubDistrictList: (payload) => dispatch(fetchSubDistrictList(payload)),
    fetchVillageList: (payload) => dispatch(fetchVillageList(payload)),
    fetchGatNoList: (payload) => dispatch(fetchGatNoList(payload)),
    createSevenEnquiryRecord: (payload) =>
      dispatch(createSevenEnquiryRecord(payload)),
    initSevenEnquiry: () => dispatch(initSevenEnquiry())
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    provincesList: state.provinceReducer.provincesList,
    districtsList: state.districtReducer.districtsList,
    subDistrictsList: state.subDistrictReducer.subDistrictsList,
    villagesList: state.villageReducer.villagesList,
    gatNosList: state.gatNoReducer.gatNosList,
    formFieldValueMap: selectFormFieldValueMap(state.sevenEnquiryReducer),
    isPageLevelError: state.sevenEnquiryReducer?.isPageLevelError,
    isLoading: state.sevenEnquiryReducer?.isLoading,
    error: state.sevenEnquiryReducer?.error,
    isCreateSevenEnquirySuccess:
      state.sevenEnquiryReducer?.isCreateSevenEnquirySuccess,
    isCreateSevenEnquiryError:
      state.sevenEnquiryReducer?.isCreateSevenEnquiryError,
    isSevenEnquiryDetailError:
      state.sevenEnquiryReducer?.isSevenEnquiryDetailError,
    isSevenEnquiryDetailSuccess:
      state.sevenEnquiryReducer?.isSevenEnquiryDetailSuccess
  }
}

const selectFormFieldValueMap = (sevenEnquiryReducer) => {
  return sevenEnquiryReducer?.formFieldValueMap
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditSevenEnquiryScreen)

import { Button } from 'primereact/button'
import React, { useEffect, useRef, useState, useCallback } from 'react'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import html2canvas from 'html2canvas'
import { LOGO } from '../../assets/images'
import { Tooltip } from 'primereact/tooltip'
import { useLocation } from 'react-router-dom'
import MzDialog from '../../common/MzDialog'
import AddEditSevenEnquiryScreen from '../../containers/sevenEnquiryScreen/addEditSevenEnquiryScreen/index'
const DEFAULT_CENTER = { lat: 18.590727879655105, lng: 73.74850060623852 }

const GmapComponent = (props) => {
  const {
    plotCoordinates,
    pinCoordinates,
    plotNumber,
    villageName,
    stateName,
    districtName,
    talukaName,
    plotArea,
    // shapeArea,
    shapeLength,
    govArea,
    setLoading
  } = props.gMapProps

  const [visible, setVisible] = useState(false)
  const [, setMeasuring] = useState(false)
  const [totalDistance, setTotalDistance] = useState(0)
  const [visibleinfo, setVisibleinfo] = useState(false)
  const [showMeasuring, setShowMeasuring] = useState(true)
  const [isDownload, setDownload] = useState(false)
  const [isDistanceVisible, setIsDistanceVisible] = useState(false)
  const [visibleSevenEnquiry, setVisibleSevenEnquiry] = useState(false)
  // const plotAreaInSqMeters = plotArea ? plotArea * 4046.86 : 0

  // const hectares = Math.floor(plotAreaInSqMeters / 10000)
  // const remainderSqMeters = plotAreaInSqMeters % 10000
  // const ares = Math.floor(remainderSqMeters / 100)
  // const sqMeters = Math.floor(remainderSqMeters % 100)
  // const plotAreaFormatted = plotArea
  //   ? `${hectares} H ${ares} R ${sqMeters} Sq. Mtr.`
  //   : 'N/A'

  let shapeLengthFormatted = !isNaN(shapeLength)
    ? Number(shapeLength).toFixed(2)
    : 'N/A'
  let govAreaFormatted = !isNaN(govArea) ? Number(govArea).toFixed(2) : 'N/A'

  const mapContainerRef = useRef(null)
  const mapRef = useRef(null)
  const directionsRendererRef = useRef(null)
  const directionsServiceRef = useRef(null)
  const polylineRef = useRef(null)
  const infoWindowRef = useRef(null)

  const location = useLocation()

  const isDashboardLocation = location.pathname === '/dashbaord/location'

  useEffect(() => {
    if (plotCoordinates?.length > 0) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [plotCoordinates])

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      return new Promise((resolve, reject) => {
        if (window.google && window.google.maps) {
          resolve()
          return
        }
        const script = document.createElement('script')
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDDSTNCtV1XVngi_7dyaKxFeOL-paKpWr0&libraries=drawing,geometry&callback=initMap`
        script.async = true
        script.defer = true
        script.onload = resolve
        script.onerror = reject
        document.body.appendChild(script)
      })
    }

    const initializeMap = () => {
      const mapCenter = pinCoordinates?.[0] || DEFAULT_CENTER
      mapRef.current = new window.google.maps.Map(mapContainerRef.current, {
        zoom: pinCoordinates ? 18.5 : 13,
        center: mapCenter,
        scaleControl: true,
        // mapTypeId: window.google.maps.MapTypeId.SATELLITE,
        mapTypeId: window.google.maps.MapTypeId.HYBRID,
        mapTypeControl: true
      })

      directionsServiceRef.current = new window.google.maps.DirectionsService()
      directionsRendererRef.current =
        new window.google.maps.DirectionsRenderer()
      directionsRendererRef.current.setMap(mapRef.current)

      if (plotCoordinates && Array.isArray(plotCoordinates)) {
        plotCoordinates.forEach((coords) => {
          new window.google.maps.Polygon({
            paths: coords,
            strokeColor: '#0bb980',
            strokeOpacity: 0.9,
            strokeWeight: 2,
            fillColor: '#0bb980',
            fillOpacity: 0.3
          }).setMap(mapRef.current)
        })

        const flattenedCoordinates = plotCoordinates.flat()
        const distances = calculatePolygonDistances(flattenedCoordinates)

        displayDistancesOnMap(distances)
      }

      if (pinCoordinates) {
        pinCoordinates.forEach((pinCoord) => {
          new window.google.maps.Marker({
            position: pinCoord,
            map: mapRef.current,
            title: plotNumber || '',
            label: {
              text: plotNumber || '',
              color: 'white',
              fontSize: '11px',
              fontWeight: 'bold'
            },
            icon: {
              path: window.google.maps.SymbolPath.CIRCLE,
              scale: 15,
              fillColor: 'green',
              fillOpacity: 1,
              strokeWeight: 2,
              strokeColor: 'white'
            }
          })
        })
      }
    }

    window.initMap = initializeMap
    loadGoogleMapsScript().then(() => {
      if (document.readyState === 'complete') {
        initializeMap()
      }
    })
    function displayDistancesOnMap(distances) {
      if (!distances || !distances.length) return

      distances.forEach((distObj) => {
        const { distance, coordinates } = distObj
        const [point1, point2] = coordinates

        const midpoint = {
          lat: (point1.lat + point2.lat) / 2,
          lng: (point1.lng + point2.lng) / 2
        }

        new window.google.maps.Marker({
          position: midpoint,
          map: mapRef.current,
          label: {
            text: `${distance.toFixed(2)} m.`,
            color: 'white',
            fontSize: '10px',
            fontWeight: 'bold'
          },
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 0
          }
        })
        ;[point1, point2].forEach((point) => {
          new window.google.maps.Marker({
            position: point,
            map: mapRef.current,
            icon: {
              path: window.google.maps.SymbolPath.CIRCLE,
              scale: 5,
              fillColor: 'red',
              fillOpacity: 1,
              strokeWeight: 2,
              strokeColor: 'white'
            }
          })
        })
      })
    }

    return () => {
      window.initMap = undefined
      if (mapRef.current) mapRef.current = null
      if (directionsRendererRef.current)
        directionsRendererRef.current.setMap(null)
      if (directionsServiceRef.current) directionsServiceRef.current = null
      if (polylineRef.current) polylineRef.current.setMap(null)
      if (infoWindowRef.current) infoWindowRef.current.close()
    }
  }, [pinCoordinates, plotCoordinates, plotNumber])

  const createPencilCursor = () => {
    const canvas = document.createElement('canvas')
    canvas.width = 32
    canvas.height = 32
    const ctx = canvas.getContext('2d')
    ctx.fillStyle = 'white'
    ctx.font = '25px "Pencil Icon"'
    ctx.textBaseline = 'middle'

    ctx.translate(canvas.width / 2, canvas.height / 2)
    ctx.rotate(Math.PI / -1.4)
    ctx.fillText('\u270F', -12, 0)
    return canvas.toDataURL('image/png')
  }

  const [dragging, setDragging] = useState(false)
  const [draggingIndex, setDraggingIndex] = useState(null)

  const startMeasuring = () => {
    if (mapRef.current) {
      setMeasuring(true)
      setTotalDistance(0)
      polylineRef.current = new window.google.maps.Polyline({
        map: mapRef.current,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 3,
        editable: true
      })

      mapRef.current.setOptions({
        draggableCursor: `url(${createPencilCursor()}), auto`
      })

      mapRef.current.addListener('click', addPoint)

      mapRef.current.addListener('mousemove', (event) => {
        if (dragging && draggingIndex !== null) {
          const path = polylineRef.current.getPath()
          path.setAt(draggingIndex, event.latLng)
          updateDistance(path)
        }
      })

      mapRef.current.addListener('mousedown', (event) => {
        if (polylineRef.current) {
          const path = polylineRef.current.getPath()
          const latLng = event.latLng

          let closestPointIndex = -1
          let minDistance = Infinity

          path.forEach((point, index) => {
            const distance =
              window.google.maps.geometry.spherical.computeDistanceBetween(
                point,
                latLng
              )
            if (distance < minDistance) {
              minDistance = distance
              closestPointIndex = index
            }
          })

          if (
            closestPointIndex === 0 ||
            closestPointIndex === path.getLength() - 1
          ) {
            if (minDistance < 10) {
              setDragging(true)
              setDraggingIndex(closestPointIndex)
            }
          }
        }
      })

      mapRef.current.addListener('mouseup', () => {
        setDragging(false)
        setDraggingIndex(null)
      })

      polylineRef.current.getPath().addListener('set_at', () => {
        updateDistance(polylineRef.current.getPath())
      })

      polylineRef.current.getPath().addListener('insert_at', () => {
        updateDistance(polylineRef.current.getPath())
      })

      polylineRef.current.getPath().addListener('remove_at', () => {
        updateDistance(polylineRef.current.getPath())
      })
    }
  }

  const handleToggle = () => {
    setVisibleinfo(!visibleinfo)
  }
  const handleToggleinfo = () => {
    setShowMeasuring(!showMeasuring)
  }
  const handleClose = () => {
    setVisibleinfo(false)
  }

  const addPoint = (event) => {
    if (!polylineRef.current || dragging) return

    const path = polylineRef.current.getPath()
    if (!path) return

    if (
      path.getLength() > 0 &&
      path.getAt(path.getLength() - 1).equals(event.latLng)
    ) {
      return
    }

    path.push(event.latLng)

    updateDistance(path)

    setIsDistanceVisible(true)
  }

  const updateDistance = useCallback((path) => {
    let totalDistance = 0

    for (let i = 1; i < path.getLength(); i++) {
      const startPoint = path.getAt(i - 1)
      const endPoint = path.getAt(i)
      const segmentDistance =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          startPoint,
          endPoint
        )
      totalDistance += segmentDistance
    }

    setTotalDistance(totalDistance)

    setIsDistanceVisible(totalDistance > 0)
  }, [])

  const undoLastPoint = useCallback(() => {
    if (!polylineRef.current) return

    const path = polylineRef.current.getPath()
    if (path.getLength() > 0) {
      path.pop()
      updateDistance(path)
    }
    if (path.getLength() === 0) {
      setIsDistanceVisible(false)
    }
  }, [updateDistance, setIsDistanceVisible])

  useEffect(() => {
    const handleUndoShortcut = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
        event.preventDefault()
        undoLastPoint()
      }
    }
    window.addEventListener('keydown', handleUndoShortcut)
    return () => {
      window.removeEventListener('keydown', handleUndoShortcut)
    }
  }, [undoLastPoint])

  const clearMeasurement = () => {
    if (polylineRef.current) {
      polylineRef.current.setMap(null)
      polylineRef.current = null
    }

    if (infoWindowRef.current) {
      infoWindowRef.current.close()
      infoWindowRef.current = null
    }
    setMeasuring(false)
    setTotalDistance(0)
    if (mapRef.current) {
      mapRef.current.setOptions({
        draggableCursor: null
      })
    }
    setIsDistanceVisible(false)
  }

  const handleGetDirections = () => {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by this browser.')
      return
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }

        if (!pinCoordinates || pinCoordinates.length === 0) {
          console.error('Pin coordinates are not available.')
          return
        }

        const destination = pinCoordinates[0]
        const directionsUrl = `https://www.google.com/maps/dir/?api=1&origin=${userLocation.lat},${userLocation.lng}&destination=${destination.lat},${destination.lng}&travelmode=driving`

        const userAgent = navigator.userAgent
        const isMobile = /Mobi|Android/i.test(userAgent)
        const isiOS = /iPhone|iPad|iPod/i.test(userAgent)

        if (isMobile) {
          const mobileUrl = `geo:${userLocation.lat},${userLocation.lng}?q=${destination.lat},${destination.lng}`
          window.location.href = mobileUrl
        } else if (isiOS) {
          const appleMapsUrl = `https://maps.apple.com/?saddr=${userLocation.lat},${userLocation.lng}&daddr=${destination.lat},${destination.lng}&dirflg=d`
          window.location.href = appleMapsUrl
        } else {
          window.open(directionsUrl, '_blank')
        }
      },
      (error) => {
        console.error("Error getting user's location:", error)
        setLoading(true)
      }
    )
  }

  const handleDownload = async () => {
    setDownload(true)
    const isMobile = window.innerWidth <= 768
    const orientation = isMobile ? 'portrait' : 'landscape'

    mapContainerRef.current.style.width = isMobile ? '100vw' : '100%'
    mapContainerRef.current.style.height = isMobile
      ? 'calc(66vh - 10px)'
      : '100vh'

    const style = document.createElement('style')
    style.innerHTML =
      '.gmnoprint { display: none !important; }.gm-control-active{display: none !important; }'
    document.head.appendChild(style)

    await new Promise((resolve) => setTimeout(resolve, 100))

    const mapCanvas = await html2canvas(mapContainerRef.current, {
      useCORS: true,
      scale: 3
    })
    document.head.removeChild(style)
    const mapImage = mapCanvas.toDataURL('image/png')

    const doc = new jsPDF({
      orientation: orientation,
      unit: 'mm',
      format: 'a4'
    })

    const pageWidth = orientation === 'landscape' ? 297 : 210
    const pageWidthMobile = orientation === 'landscape' ? 250 : 210
    const pageHeight = orientation === 'landscape' ? 210 : 297
    const imgWidth = pageWidth - 20
    const imgHeight = (mapCanvas.height * imgWidth) / mapCanvas.width

    const logo = new Image()
    logo.src = LOGO
    doc.addImage(logo, 'PNG', 8, 8, 18, 18)

    doc.setFontSize(25)
    doc.setTextColor(87, 187, 91)
    doc.setFont('helvetica', 'bold')
    doc.text('Bhoomi 22', pageWidth / 2, 18, { align: 'center' })

    doc.addImage(mapImage, 'PNG', 10, 30, imgWidth, imgHeight)

    let startY = 30 + imgHeight + 10
    if (startY + 40 > pageHeight) {
      doc.addPage()
      startY = 20
    }

    const tableWidth = isMobile ? pageWidthMobile * 0.29 : pageWidth * 0.28

    doc.autoTable({
      startY: startY,
      head: [['Plot Number', 'Village', 'Taluka', 'District', 'State']],
      body: [
        [
          plotNumber || 'N/A',
          villageName || 'N/A',
          talukaName || 'N/A',
          districtName || 'N/A',
          stateName || 'N/A'
        ]
      ],
      margin: { top: startY },
      styles: {
        fontSize: 12,
        minCellHeight: 10,
        cellPadding: 2,
        halign: 'center',
        valign: 'middle'
      },
      theme: 'grid',
      headStyles: {
        fillColor: [87, 187, 91]
      },
      didDrawPage: function (data) {
        if (data.cursor.y + 40 > pageHeight) {
          doc.addPage()
          startY = 20
        }
      }
    })

    startY = doc.lastAutoTable.finalY + 10
    if (startY + 40 > pageHeight) {
      doc.addPage()
      startY = 20
    }

    doc.autoTable({
      startY: startY,
      head: [['Description', 'Measurement']],
      body: [
        ['Map', '-'],
        ['7/12', '-'],
        ['Shape', '-'],
        ['Area', govAreaFormatted !== 'N/A' ? `${govAreaFormatted} ` : 'N/A']
      ],
      theme: 'grid',
      headStyles: {
        fillColor: [87, 187, 91]
      },
      styles: {
        fontSize: isMobile ? 10 : 12,
        minCellHeight: 10,
        cellPadding: 2,
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2
      },
      tableWidth: tableWidth,
      didDrawPage: function (data) {
        if (data.cursor.y + 40 > pageHeight) {
          doc.addPage()
          startY = 20
        }
      }
    })

    const thirdTableStartX = tableWidth + 18
    const thirdTableWidth = isMobile ? pageWidthMobile * 0.2 : pageWidth * 0.22

    doc.autoTable({
      startY: startY,
      head: [['Direction', 'Survey.No']],
      body: [
        ['North', ''],
        ['East', ''],
        ['West', ''],
        ['South', '']
      ],
      margin: { top: startY, left: thirdTableStartX },
      theme: 'grid',
      headStyles: {
        fillColor: [87, 187, 91]
      },
      styles: {
        fontSize: isMobile ? 10 : 12,
        minCellHeight: 10,
        cellPadding: 2,
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2
      },
      columnStyles: isMobile
        ? {
            0: { cellWidth: 20 },
            1: { cellWidth: 50 }
          }
        : {
            0: { cellWidth: 30 },
            1: { cellWidth: 80 }
          },
      tableWidth: thirdTableWidth,
      didDrawPage: function (data) {
        if (data.cursor.y + 40 > pageHeight) {
          doc.addPage()
          startY = 20
        }
      }
    })

    const thirdTableEndX = isMobile
      ? thirdTableStartX + thirdTableWidth + 33
      : thirdTableStartX + thirdTableWidth + 50
    const forthTableWidth = isMobile ? pageWidthMobile * 0.2 : pageWidth * 0.22

    doc.autoTable({
      startY: startY,
      head: [['Compound Length']],
      body: [
        [shapeLengthFormatted !== 'N/A' ? `${shapeLengthFormatted} m` : 'N/A'],
        ['Contact Us']
      ],
      margin: { top: startY, left: thirdTableEndX },
      theme: 'grid',
      headStyles: {
        fillColor: [87, 187, 91]
      },
      styles: {
        fontSize: isMobile ? 10 : 12,
        minCellHeight: 10,
        cellPadding: 2,
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2
      },
      tableWidth: forthTableWidth,
      didDrawCell: function (data) {
        if (
          data.section === 'body' &&
          data.column.index === 0 &&
          data.row.index === 1
        ) {
          const { x, y } = data.cell
          const cellWidth = data.cell.width
          const cellHeight = data.cell.height

          doc.link(x, y, cellWidth, cellHeight, {
            url: 'https://bhoomi22.com/contactus'
          })

          doc.setTextColor(59, 130, 243)

          const text = 'Contact Us'
          doc.text(text, x + cellWidth / 2, y + cellHeight / 2, {
            align: 'center',
            baseline: 'middle'
          })

          const textWidth = doc.getTextWidth(text)
          const underlineY = y + cellHeight / 2 + 2
          doc.setDrawColor(59, 130, 243)
          doc.setLineWidth(0.5)
          doc.line(
            x + cellWidth / 2 - textWidth / 2,
            underlineY,
            x + cellWidth / 2 + textWidth / 2,
            underlineY
          )

          doc.setTextColor(0, 0, 0)
        }
      }
    })

    const footerMargin = 10
    doc.setFontSize(10)
    doc.setTextColor(100, 100, 100)
    doc.text('© Bhoomi22. 2024', pageWidth / 2, pageHeight - footerMargin, {
      align: 'center'
    })

    startY = doc.lastAutoTable.finalY + 35

    const disclaimer =
      'Disclaimer: Area is calculated based on geometry and it may vary from area mentioned in 7/12 document. Bhoomi22.com do not gurantee the correctness of the data. Data provided in this report is sourced from information available in the public domain. This data is to be used for information purposes only and cannot be used for any legal purpose.'
    const wrappedDisclaimer = doc.splitTextToSize(disclaimer, pageWidth - 30)

    doc.text(wrappedDisclaimer, 15, startY, {
      align: 'left',
      fontSize: isMobile ? 10 : 12
    })

    const fileName = `${plotNumber}-${villageName}-${districtName}.pdf`
    doc.save(fileName)
    mapContainerRef.current.style.width = '100%'
    mapContainerRef.current.style.height = 'calc(99vh - 4rem)'

    if (mapRef.current) {
      window.google.maps.event.trigger(mapRef.current, 'resize')
      mapRef.current.setCenter(pinCoordinates?.[0] || DEFAULT_CENTER)
    }
    setDownload(false)
  }

  const handleSevenEnquiry = () => {
    setVisibleSevenEnquiry(true)
  }

  function calculatePolygonDistances(plotCoordinates) {
    const flattenedCoordinates = Array.isArray(plotCoordinates[0])
      ? plotCoordinates.flat()
      : plotCoordinates

    const R = 6371e3
    const toRadians = (degrees) => degrees * (Math.PI / 180)
    const distances = []

    for (let i = 0; i < flattenedCoordinates.length - 1; i++) {
      const point1 = flattenedCoordinates[i]
      const point2 = flattenedCoordinates[i + 1]

      const lat1 = toRadians(point1.lat)
      const lon1 = toRadians(point1.lng)
      const lat2 = toRadians(point2.lat)
      const lon2 = toRadians(point2.lng)
      const dLat = lat2 - lat1
      const dLon = lon2 - lon1
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1) *
          Math.cos(lat2) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      const distance = R * c
      distances.push({
        distance,
        coordinates: [point1, point2]
      })
    }
    return distances
  }

  calculatePolygonDistances(plotCoordinates)
  return (
    <div>
      <div
        className="cardinfo relative justify-content-center   w-full h-full"
        style={isDashboardLocation ? {} : { marginTop: '72px' }}
      >
        {visibleinfo && (
          <div className="cardinfo z-3 absolute right-0 mr-8 mt-8   border-round bg-white px-2 custom-margin">
            <Button
              icon="pi pi-times"
              className="p-button-text p-button-sm"
              onClick={handleClose}
              style={{
                position: 'absolute',
                top: '-3px',
                right: '-3px',
                color: 'red',
                zIndex: 10
              }}
            />
            <ul className="    p-0 ">
              <div className="text-center mb-2">
                <div className="text-900 text-sm">
                  <div className="text-900 text-xs">{plotNumber || ''}</div>
                  <div className="text-600 text-xs">{villageName || ''}</div>
                  <div className="flex justify-content-center w-full ">
                    <div className="text-900 text-xs">{districtName || ''}</div>
                    , &nbsp;
                    <div className="text-900 text-xs">{stateName || ''}</div>
                  </div>
                </div>
              </div>

              <li className="flex items-center mb-2">
                <i className="pi pi-play-circle text-green-500 mr-2"></i>
                <span className="text-600 text-xs">Area:</span>
                <span className="text-900 ml-2 text-xs">
                  {plotArea ? plotArea : 'N/A'} <sup></sup>
                </span>
              </li>
            </ul>
          </div>
        )}
        <div
          id="map"
          ref={mapContainerRef}
          style={{ height: 'calc(99vh - 4rem)', width: '100%' }}
        ></div>

        {visible && (
          <>
            <div className="grid top-0 right-0 flex-column mt-2 absolute mt-6 p-2">
              {plotCoordinates && showMeasuring && (
                <div className="col-6 md:col-3 p-1">
                  <Button
                    raised
                    severity="secondary"
                    outlined
                    icon=" pi pi-pencil text-700 text-xs"
                    className="bg-white no-outline w-xs sidebutton"
                    onClick={() => {
                      startMeasuring()
                      handleToggleinfo()
                    }}
                    data-pr-tooltip="start measurement"
                    data-pr-position="left"
                    disabled={!plotCoordinates}
                  />
                  <Tooltip target=".p-button" />
                </div>
              )}

              {plotCoordinates && !showMeasuring && (
                <div className="col-6 md:col-3 p-1">
                  <Button
                    raised
                    severity="secondary"
                    outlined
                    icon=" pi pi-eraser text-700 text-xs"
                    className="bg-white no-outline w-xs sidebutton"
                    onClick={() => {
                      clearMeasurement()
                      handleToggleinfo()
                    }}
                    data-pr-tooltip="Eraser"
                    data-pr-position="left"
                    disabled={!plotCoordinates}
                  />
                  <Tooltip target=".p-button" />
                </div>
              )}
              {pinCoordinates && (
                <>
                  <div className="col-6 md:col-3 p-1">
                    <Button
                      severity="secondary"
                      outlined
                      icon=" pi pi-directions text-700 text-xs"
                      className="bg-white no-outline w-xs sidebutton"
                      onClick={handleGetDirections}
                      data-pr-tooltip="Get direction"
                      data-pr-position="left"
                      disabled={!pinCoordinates}
                    />
                    <Tooltip target=".p-button" />
                  </div>
                  {isDownload && (
                    <div className="progress-spinner">
                      <div className="custom-spinner"></div>
                    </div>
                  )}
                </>
              )}

              {/* {plotCoordinates && (
              <div className='col-6 md:col-3 p-1'>
                <Button
                  raised
                  severity='secondary'
                  outlined
                  icon=' pi pi-arrow-circle-down text-700 text-xs'
                  className='bg-white no-outline w-xs sidebutton'
                  onClick={handleDownload}
                  data-pr-tooltip='Download pdf'
                  data-pr-position='left'
                  disabled={!plotCoordinates}
                />
                <Tooltip target='.p-button' />
              </div>
            )} */}

              {plotCoordinates && (
                <>
                  <div className="col-6 md:col-3 p-1">
                    <Button
                      raised
                      severity="secondary"
                      outlined
                      icon="pi pi-arrow-circle-down text-700 text-xs"
                      className="bg-white no-outline w-xs sidebutton"
                      onClick={handleDownload}
                      data-pr-tooltip="Download pdf"
                      data-pr-position="left"
                      disabled={!plotCoordinates || isDownload}
                    />
                    <Tooltip target=".p-button" />
                  </div>
                  {isDownload && (
                    <div className="progress-spinner">
                      <div className="custom-spinner"></div>
                    </div>
                  )}
                </>
              )}
              <div className="col-6 md:col-3 p-1">
                <Button
                  severity="secondary"
                  outlined
                  // severity="info"
                  icon="pi pi-info-circle text-700 text-xs"
                  className="bg-white no-outline w-xs sidebutton"
                  onClick={handleToggle}
                  data-pr-tooltip="Information"
                  data-pr-position="left"
                />
                <Tooltip target=".p-button" />
              </div>

              <div className="col-6 md:col-3 p-1">
                <Button
                  severity="secondary"
                  outlined
                  // severity="info"
                  icon="pi pi-map text-700 text-xs"
                  className="bg-white no-outline w-xs sidebutton"
                  onClick={handleSevenEnquiry}
                  data-pr-tooltip="7/12 or Index2"
                  data-pr-position="left"
                />
                <Tooltip target=".p-button" />
              </div>

              <div className="col-6 md:col-3 p-1">
                <Button
                  severity="secondary"
                  outlined
                  // severity="info"
                  icon="pi pi-arrow-left text-700 text-xs"
                  className="bg-white no-outline w-xs sidebutton"
                  onClick={undoLastPoint}
                  data-pr-tooltip="Undo Last Edit"
                  data-pr-position="left"
                />
                <Tooltip target=".p-button " />
              </div>
            </div>
            {isDistanceVisible && (
              <div className="grid top-0 w-4 md:w-2 lg:w-1 right-0 flex-row mb-8 mr-6 absolute">
                <div className="col-12 md:col-12 ">
                  <div className="distance-container text-center">
                    <p> {totalDistance.toFixed(2)} m</p>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <MzDialog
        onHide={() => setVisibleSevenEnquiry(false)}
        visible={visibleSevenEnquiry}
        children={
          <AddEditSevenEnquiryScreen
            setvisibleSevenEnquiry={setVisibleSevenEnquiry}
          />
        }
      ></MzDialog>
    </div>
  )
}

export default GmapComponent

import axios from 'axios'
import { API_PATH } from '../../constant/urlConstant'
import { handleAPIError } from '../common/errorHandler'
import { baseUrl } from '../PostAPI'
import { getDecoratedUrl } from '../common/urlService'
// import { USER_ROLE } from "../../constant/actionTypes/role";

export const fetchSevenEnquiryList = async (payload) => {
  let url = `${baseUrl}${API_PATH.SEVEN_ENQUIRY.FETCH}`
  let finalUrl
  finalUrl = getDecoratedUrl({ url, payload, shouldFilter: false })

  const token = localStorage.getItem('token')
  try {
    const result = await axios.get(finalUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (result.data.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }

    return result.data
  } catch (error) {
    console.error(error)
    return handleAPIError(error)
  }
}
export const createSevenEnquiryRecord = async (payload) => {
  const url = `${baseUrl}${API_PATH.SEVEN_ENQUIRY.ADD}`
  try {
    const result = await axios.post(url, payload, {})
    if (result?.data?.error || result.status !== 200) {
      return handleAPIError(result.data.detail)
    }
    return result.data
  } catch (error) {
    return handleAPIError(error.response.data.message)
  }
}

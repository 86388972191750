import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { deleteUser, fetchUserList, initUser } from '../../redux/action/user'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../constant/urlConstant'
import userTableData from './data.json'
// import { USER_ROLE } from '../../constant/actionTypes/role';
import { ProgressBar } from 'primereact/progressbar'
import { MzToast, TOAST_SEVERITY } from '../../common/MzToast'
import UserComponent from '../../components/user'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'

const UserScreen = (props) => {
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false)
  // const [isReadDailogVisible, setIsReadDialogVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null)
  const history = useNavigate()

  const {
    fetchUserList,
    initUser,
    userList,
    isLoading,
    isPageLevelError,
    // error,
    userRole,
    deleteUser,
    isDeleteUserSuccess,
    isDeleteUserError
  } = props

  useEffect(() => {
    initUser()
    const paylaod = {
      pageNumber: 0,
      pageSize: 10
    }
    fetchUserList(paylaod)
    // eslint-disable-next-line
  }, [isDeleteUserSuccess])

  const handleOnReadRecord = (data) => {
    setSelectedUser(data)
  }

  const handleOnDeleteRecord = (data) => {
    setSelectedUser(data)
    setIsDeleteDialogVisible(true)
  }

  const handleOnEditRecord = (data) => {
    history(`${ROUTE_PATH.USER.EDIT.replace(':id', data?.id)}`)
  }

  const handleOnCreatedRecord = () => {
    history(ROUTE_PATH.USER.ADD)
  }

  const confirmDelete = () => {
    if (selectedUser) {
      deleteUser(selectedUser.id)
      setIsDeleteDialogVisible(false)
      setSelectedUser(null)
    }
  }

  const cancelDelete = () => {
    setIsDeleteDialogVisible(false)
    setSelectedUser(null)
  }

  const userProps = {
    userList,
    fetchUserList,
    isPageLevelError,
    isLoading,
    userRole,
    userTableData,
    handleOnReadRecord,
    handleOnDeleteRecord,
    handleOnEditRecord,
    handleOnCreatedRecord
  }

  const renderProgressBar = () => {
    return <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
  }

  const getToastProps = () => {
    if (isDeleteUserSuccess) {
      const toastTitle = 'User Status Change Successfully'
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }

    if (isDeleteUserError) {
      let toastTitle = 'Error while Changing User Status'
      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
  }

  return (
    <>
      {isLoading && renderProgressBar()}
      <MzToast {...getToastProps()} />
      <UserComponent userProps={userProps} />

      <Dialog
        header={`Confirm ${selectedUser?.active ? 'Deactivate' : 'Activate'}`}
        visible={isDeleteDialogVisible}
        onHide={cancelDelete}
        footer={
          <div>
            <Button
              severity={selectedUser?.active ? 'danger' : ''}
              onClick={confirmDelete}
            >
              Yes
            </Button>
            <Button severity="" onClick={cancelDelete}>
              No
            </Button>
          </div>
        }
      >
        <div className="text-warp">
          Are you sure you want to{' '}
          {selectedUser?.active ? 'deactivate' : 'activate'} this User?{' '}
          <span className="text-primary">{selectedUser?.username}</span>
        </div>
      </Dialog>
    </>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
    initUser: () => dispatch(initUser()),
    fetchUserList: (payload) => dispatch(fetchUserList(payload)),
    deleteUser: (payload) => dispatch(deleteUser(payload))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isPageLevelError: state.userReducer.isPageLevelError,
    isLoading: state.userReducer.isLoading,
    error: state.userReducer.error,
    userList: state.userReducer.userList,
    isDeleteUserSuccess: state.userReducer.isDeleteUserSuccess,
    isDeleteUserError: state.userReducer.isDeleteUserError
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserScreen)

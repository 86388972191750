export const FORM_FIELDS_NAME = {
  PROVINCE: {
    name: 'state',
    label: 'State',
    type: 'dropdown',
    optionLabel: 'name',
    optionValue: 'id',
    rules: {
      required: 'State is required'
    },
    options: [],
    placeholder: 'Select a state'
  },
  DISTRICT: {
    name: 'district',
    label: 'District',
    type: 'dropdown',
    optionLabel: 'name',
    optionValue: 'id',
    rules: {
      required: 'District is required'
    },
    options: [],
    placeholder: 'Select a district'
  },
  SUB_DISTRICT: {
    name: 'taluka',
    label: 'SubDistrict',
    type: 'dropdown',
    optionLabel: 'name',
    optionValue: 'id',
    rules: {
      required: 'SubDistrict is required'
    },
    options: [],
    placeholder: 'Select a subdistrict'
  },
  VILLAGE: {
    name: 'village',
    label: 'Village',
    type: 'dropdown',
    optionLabel: 'name',
    optionValue: 'id',
    rules: {
      required: 'Village is required'
    },
    options: [],
    placeholder: 'Select a Village'
  },

  NEW_NAME: {
    name: 'name',
    label: 'Name',
    type: 'text',

    rules: {
      required: 'Name is required'
    },
    minLength: {
      value: 3,
      message: 'Taluka new name must be at least 3 characters'
    },
    placeholder: 'Enter a new name'
  }
}

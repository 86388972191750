import {
  INIT_SEVENENQUIRY,
  FETCH_SEVENENQUIRY_LIST,
  FETCH_SEVENENQUIRY_RECORD,
  CREATE_SEVENENQUIRY_RECORD,
  DELETE_SEVENENQUIRY
} from '../../../constant/actionTypes/sevenEnquiry'

const formFieldValueMap = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  enquiryType: '',
  plot: '',
  mobileNo: '',
  description: ''
}

const initialState = {
  sevenEnquiryList: [],
  isLoading: false,
  error: null,
  formFieldValueMap,
  isPageLevelError: false,
  isCreateSevenEnquirySuccess: false,
  isSevenEnquiryDetailSuccess: false,
  isSevenEnquiryDetailError: false,
  isCreateSevenEnquiryError: false,
  isDeleteSevenEnquirySuccess: false,
  isDeleteSevenEnquiryError: false
}

const sevenEnquiryReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_SEVENENQUIRY:
      return {
        ...state,
        formFieldValueMap: null,
        sevenEnquirysList: [],
        selectedSevenEnquiry: null,
        isSevenEnquiryDetailSuccess: false,
        isCreateSevenEnquirySuccess: false,
        isLoading: false,
        error: null
      }
    case FETCH_SEVENENQUIRY_LIST.START:
    case FETCH_SEVENENQUIRY_RECORD.START:
    case CREATE_SEVENENQUIRY_RECORD.START:
      return {
        ...state,
        isLoading: true,
        error: null,

        isCreateSevenEnquirySuccess: false,
        isEditSevenEnquirySuccess: false,
        isSevenEnquiryDetailSuccess: false,
        isSevenEnquiryDetailError: false,
        isCreateSevenEnquiryError: false,
        isEditSevenEnquiryError: false
      }

    case FETCH_SEVENENQUIRY_LIST.SUCCESS:
      return {
        ...state,
        // sevenEnquiryList: action?.payload?.content ?? [],
        sevenEnquiryList: action?.payload ?? [],
        isLoading: false,
        error: null,
        isSevenEnquiryDetailSuccess: true
      }

    case CREATE_SEVENENQUIRY_RECORD.SUCCESS:
      return {
        ...state,
        sevenEnquiryList: [...state?.sevenEnquiryList, action.payload],
        isLoading: false,
        error: null,
        isCreateSevenEnquirySuccess: true
      }

    case DELETE_SEVENENQUIRY.SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        isDeleteSevenEnquirySuccess: true
      }
    case DELETE_SEVENENQUIRY.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isDeleteSevenEnquirySuccess: true
      }
    case FETCH_SEVENENQUIRY_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case FETCH_SEVENENQUIRY_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        isSevenEnquiryDetailError: true
      }
    case CREATE_SEVENENQUIRY_RECORD.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.error?.error,
        isCreateSevenEnquiryError: true
      }
    default:
      return state
  }
}

export default sevenEnquiryReducer
